import $axios from "../../plugins/axios";

// store/modules/spotify.js
const state = {
    token: null,
    player: null,
    deviceId: null,
    spotifyRefreshToken: null,
    isSpotifyPlaying: false,
    hasRefreshedToken: false,
};

const mutations = {
    setSpotifyToken(state, token) {
        state.token = token;
    },
    setSpotifyPlayer(state, player) {
        state.player = player;
    },
    setDeviceId(state, deviceId) {
        state.deviceId = deviceId;
    },
    setSpotifyRefreshToken(state, spotifyRefreshToken) {
        state.spotifyRefreshToken = spotifyRefreshToken;
    },
    setPlayingStatus(state, status) {
        state.isSpotifyPlaying = status;
    }
};

const actions = {
    updateSpotifyToken({ commit }, token) {
        commit('setSpotifyToken', token);
    },
    updateSpotifyPlayer({ commit }, player) {
        commit('setSpotifyPlayer', player); 
    },
    updateDeviceId({ commit }, deviceId) {
        commit('setDeviceId', deviceId);
    },
    updateSpotifyRefreshToken({ commit }, spotifyRefreshToken) {
        commit('setSpotifyRefreshToken', spotifyRefreshToken);
    },
    async refreshAccessToken({ commit ,state}) {
        const refreshToken = state.spotifyRefreshToken;
        try {
            const response = await $axios.post('/spotify/refresh', { refreshToken });
            const spotifyAccessToken = response.data.access_token;
            if (spotifyAccessToken) {
                await commit('setSpotifyToken', spotifyAccessToken); // Update token in Vuex
                localStorage.setItem('spotifyToken', spotifyAccessToken);
            } else {
                throw new Error('Failed to refresh access token');
            }
            return spotifyAccessToken;
        } catch (error) {
            console.error("Error refreshing access token:", error);
            throw error;
        }
    },
    async initSpotifyPlayer({ commit, dispatch, state }, token) {
        return new Promise((resolve, reject) => {
            const player = new window.Spotify.Player({
                name: 'ReflexRadio Player',
                getOAuthToken: cb => { cb(token); },
                volume: 0.5,
            });

            player.addListener('ready', ({ device_id }) => {
                commit('setSpotifyPlayer', player);
                commit('setDeviceId', device_id);
                resolve(player);
            });
            player.addListener('not_ready', ({ device_id }) => {
                //console.log('Device ID has gone offline', device_id);
                reject(new Error('Device not ready.'));
            });
            player.addListener('initialization_error', ({ message }) => {
                console.error('Initialization error:', message);
                reject(new Error(`Initialization error: ${message}`)); // Reject with the error message
            });
    
            player.addListener('authentication_error', async ({ message}) => {
                console.error('Authentication error:', message);
                reject(new Error(`Authentication error: ${message}`)); // Reject with the error message
                console.warn('Authentication error detected. Refreshing token...');
                try {
                    const newToken = await dispatch('refreshAccessToken');
                    await commit('setSpotifyToken', newToken);
                    player.disconnect();
                    //console.log("new token =", state.token, newToken)
                    await dispatch('initSpotifyPlayer', newToken); // Reinitialize with the new token
                } catch (error) {
                    console.error('Failed to refresh token and reconnect player:', error);
                    reject(error);
                }
            });

            player.addListener('playback_error', ({ message }) => {
                console.error('Playback error:', message);
                reject(new Error(`Playback error: ${message}`)); // Reject with the error message
            });
            player.on('account_error', ({ message }) => {
                this.dispatch('showSnackbarMessage', {
                    message: "Failed to validate Spotify account "+ message,
                    duration: 6000,
                    mode: 'fail'
                });
              });
            player.connect().then(success => {
                if (!success) {
                    reject(new Error('Failed to connect to the player.')); // Reject if connection fails
                }
            }).catch(async err => {
                console.error('Error during player connection:', err);
                reject(new Error('Error connecting to the player.')); // Handle connection error
                await dispatch('refreshAccessToken');  // Correct way to call another action

            });
            
        });
    },
    async playSong({ state, dispatch }, trackUri) {
        return dispatch('performWithTokenRefresh', async () => {

            const player = state.player;
            const deviceId = state.deviceId;
            const token = state.token;
        
            if (player && deviceId) {
                try {
                    const response = await fetch(`https://api.spotify.com/v1/me/player/play?device_id=${deviceId}`, {
                        method: 'PUT',
                        body: JSON.stringify({ uris: [trackUri] }),
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                    });
                    //console.log("play res =>" ,response)
        
                    if (response.ok) {
                        // await player.resume().then(() => {
                            state.isSpotifyPlaying = true;
                            dispatch('currentState');
                        // });
                        //console.log('Playback started');
                    } else {
                        const errorData = await response.json();
                        console.error('Failed to start playback:', errorData);
                        state.isSpotifyPlaying = false;
    
                        throw new Error(`Playback failed: ${errorData.error.message}`);
                    }
                } catch (err) {
                    console.error('Error playing song:', err);
                    state.isSpotifyPlaying = false;
                    this.dispatch('showSnackbarMessage', {
                        message: err.message,
                        duration: 4000,
                        mode: 'fail'
                    });
                    throw err;
                }
            } else {
                console.error("Player or device ID is not initialized.");
                state.isSpotifyPlaying = false;
                throw new Error("Player or device ID is not initialized.");
            }
        });
    },    
    async playSongAndPause({ state , dispatch}, trackUri) {
        return dispatch('performWithTokenRefresh', async () => {
            let player = state.player;
            let deviceId = state.deviceId;
            let token = state.token;
    
            if(!player){
                await dispatch('refreshAccessToken');  // Correct way to call another action
                token = state.token;
                await dispatch('initSpotifyPlayer', token);  // Correct way to initialize the player
            }
            player = state.player;
            deviceId = state.deviceId;
    
    
            if (player && deviceId) {
                try {
                    await dispatch('setVolume', 0);
                    const response = await fetch(`https://api.spotify.com/v1/me/player/play?device_id=${deviceId}`, {
                        method: 'PUT',
                        body: JSON.stringify({ uris: [trackUri] }),
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                    });
                    //console.log("play res =>" ,response, )

                    if (response.ok) {
                        await new Promise(resolve => setTimeout(resolve, 500));  // Short delay before pausing
                        await dispatch('retryPause', 2); 


                    } else {
                        const errorData = await response.json();
                        console.error('Failed to start playback:', errorData);
                        state.isSpotifyPlaying = false;
                        throw new Error(`Playback failed: ${errorData.error.message}`);
                    }
                } catch (err) {
                    console.error('Error playing song:', err);
                    state.isSpotifyPlaying = false;
                    this.dispatch('showSnackbarMessage', {
                        message: err.message,
                        duration: 4000,
                        mode: 'fail'
                    });
                    throw err;
                }
            } else {
                console.error("Player or device ID is not initialized.");
                state.isSpotifyPlaying = false;
                throw new Error("Player or device ID is not initialized.");
            }
        });

    },
    // Helper function to retry pausing the player
    async  retryPause({state}, maxRetries) {
        const player = state.player;
        let attempt = 0;
        while (attempt < maxRetries) {
            await player.pause();
            const currentState = await player.getCurrentState();
            //console.log("currentState.paused", currentState.paused)
            if (currentState && currentState.paused) {
                //console.log("Paused successfully on attempt", attempt + 1);
                state.isSpotifyPlaying = false;
                return;  // Exit once successfully paused
            }
            attempt++;
            //console.log(`Retrying pause (Attempt ${attempt + 1} of ${maxRetries})`);
            await new Promise(resolve => setTimeout(resolve, 200));  // Delay before retry
        }
        console.warn("Failed to pause after max retries");
        state.isSpotifyPlaying = true;  // Indicate player might still be playing
    },
    async togglePlay({ state, dispatch }) {
        return dispatch('performWithTokenRefresh', async () => {
            //console.log("toggle ...................")
            const player = state.player;
            if (!player) {
                console.error("Player is not initialized.");
                return;
            }
    
            try {
                await player.togglePlay();
                state.isSpotifyPlaying = !state.isSpotifyPlaying;
                //console.log('Toggled playback!');
            } catch (error) {
                console.error('Error toggling playback:', error);
            }
        });
    },
    async pause({ state, dispatch }) {
        return dispatch('performWithTokenRefresh', async () => {
            //console.log("pausing .............")
            const player = state.player;
            if (player) {
                try {
                    await player.pause().then(()=>{
                        state.isSpotifyPlaying = false;
                    });
                    //console.log('Paused playback');
                } catch (err) {
                    console.error('Error pausing:', err);
                }
            } else {
                console.error("Player is not initialized.");
            }
        });
    },
    async resume({ state, dispatch }) {
        return dispatch('performWithTokenRefresh', async () => {
            //console.log("resuming .........")
            const player = state.player;
            if (player) {
                try {
                    await player.resume().then(()=>{
                        state.isSpotifyPlaying = true;
                    });
                    //console.log('resume playback');
                } catch (err) {
                    console.error('Error resumeinng:', err);
                }
            } else {
                console.error("Player is not initialized.");
            }
        });
    },   
    async nextTrack({ state, dispatch }) {
        return dispatch('performWithTokenRefresh', async () => {
        
            const player = state.player;
            if (player) {
                try {
                    player.nextTrack().then(() => {
                        //console.log('Skipped to next track!');
                    });
                } catch (err) {
                    console.error('Error Skipped to next:', err);
                }
            } else {
                console.error("Player is not initialized.");
            }
        });
    },
    currentState({ state , dispatch}) {
        return dispatch('performWithTokenRefresh', async () => {
            try {
                const player = state.player;
                return player.getCurrentState().then((state) => {
                    if (!state) {
                        // console.error('User is not playing music through the Web Playback SDK');
                        return null; 
                    }
                    return state;
                });
            } catch (error) {
                // console.error("Player is not initialized.");
                return null;
            }
        });
       
    },
    
    async seek({ state, dispatch}, time) {
        return dispatch('performWithTokenRefresh', async () => {
            //console.log("seeking ............")
            const player = state.player;
            if (player) {
                await player.seek(time * 1000);
                //console.log("seeked to ",time * 1000)
            }
        });
    },
    async setVolume({ state, dispatch}, volume) {
        return dispatch('performWithTokenRefresh', async () => {
            //console.log("set voulme ........")
            const player = state.player;
            if (player) {
                await player.setVolume(volume).then(()=> {

                    //console.log("Volume updated", volume)
                }
            ).catch((e)=>console.log(e));
            }
        });
    },
    getVolume({state, dispatch}) {
        return dispatch('performWithTokenRefresh', async () => {
        
            return state.player.getVolume();
        });
    },
    async currentPlaybackTime({state,dispatch}) {
        return dispatch('performWithTokenRefresh', async () => {
            //console.log("currentPlaybackTime .........")
            const player = state.player;
            if (player) {
                const playbackState = await player.getCurrentState();
                return Math.floor(playbackState.position / 1000);
            }
            return false
        });
    },
    async fetchSpotifyArtWork({ state, dispatch }, trackStreamingID, size) {
        return dispatch('performWithTokenRefresh', async () => {
            const spotifyToken = state.token;

            const response = await fetch(`https://api.spotify.com/v1/tracks/${trackStreamingID.split(':').pop()}`, {
                headers: {
                    'Authorization': `Bearer ${spotifyToken}`
                }
            });
            const searchResult = await response.json();
            if (searchResult && searchResult.album && searchResult.album.images) {
                return searchResult.album.images[0].url;
            } else {
                throw new Error("Artwork not found for the given track.");
            }
        });
    },
    async performWithTokenRefresh({ state, dispatch }, actionFn) {
        try {
            // Execute the provided action
            return await actionFn();
        } catch (error) {
            // If error is due to expired token, refresh and retry
            if (error.message.includes('401') || error.message.includes('expired')) {
                console.warn('Token expired, attempting to refresh.');
                await dispatch('refreshAccessToken');
                return actionFn();
            }
            throw error;
        }
    },
};

const getters = {
    spotifyToken: (state) => state.token,
    spotifyRefreshToken: (state) => state.token,
    spotifyPlayer: (state) => state.player,
    deviceId: (state) => state.deviceId,
    isSpotifyPlaying: state => state.isSpotifyPlaying,
}

export default {
    state,
    mutations,
    actions,
    getters,
};
