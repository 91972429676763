<template>
	<div>
		<md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="true">
			<md-dialog-title style="display: flex;" class="pick-p-title figtree-normal-white-20px">
				<div style="flex:90">
					Select Playlist
				</div>
				<div class="close-dialog" @click="closeDialog">
					<md-icon>close</md-icon>
				</div>
			</md-dialog-title>

			<div class="playlist" :class="{ 'disable-all': playMix }">



				<div class="playlist__all-mixes" style="" v-for="(list, index) in playLists" :key="index + '_list'">
					<md-tooltip v-if="list.has_explicit_song" class="md-theme-light" md-direction="bottom">This playlist
						has some explicit songs please make sure you set your apple account restricted content rating
						settings to be explicit </md-tooltip>

					<md-button :disabled="playMix" style="flex:8;text-align: center;"
						@click="playPlaylistMix(list.id, list.name)">

						<md-icon class="md-icon md-icon-font md-theme-default "
							style="color:#B6B6B6;">play_arrow</md-icon>
					</md-button>
					<div style="flex:50;display: flex;gap: 10px;">
						<div style="flex:4">
							<div class="playlist-color"
								:style="{ background: list.color ? list.color : getRandomColor(index) }">
							</div>
						</div>
						<div style="flex:40;display: flex;flex-direction: column;">
							<div style="flex:10" class="figtree-normal-white-20px">
								{{ list.name }}
							</div>
							<div style="flex:10" class="figtree-normal-white-12px">

								<span v-if="list.has_explicit_song"
									class="explicit-content figtree-normal-white-12px">E</span>
								{{ list.duration ? convertHHMMSStoHMSeconds(list.duration) : list.duration }}
							</div>
						</div>
					</div>

				</div>
			</div>
		</md-dialog>
	</div>
</template>

<script>
import PlaylistService from "@/services/playlists.service"
import { RandomColorsMixin } from "../../mixins/RandomColorsMixin";
import {mapGetters} from "vuex";
import UserService from "@/services/user.service";

export default {
	name: "BluePrintsPlaylistModal",
	mixins: [RandomColorsMixin],
	props: {
		showDialog: {
			type: Boolean,
			default: false
		},

		playlistsProp: {
			type: Array,
			default: null
		}
	},

	data: () => ({
		timeLineSeconds: 0,
		songsForMix: [],
		playMix: false
		// playLists: null
	}),

	methods: {
		convertHHMMSStoHMSeconds(timeHHMMSS) {
			const timeObj = timeHHMMSS.split(':');
			let res = (Number(timeObj[0]) !== 0 ? Number(timeObj[0]) + ' hrs ' : '') + (Number(timeObj[1]) !== 0 ? Number(timeObj[1]) + ' mins ' : '') + (Number(timeObj[2]) !== 0 ? Number(timeObj[2]) + ' Secs ' : '');

			return res;
		},
		convertTimeHHMMSS(val) {

			let hhmmss = new Date(val * 1000).toISOString().substr(11, 8);

			return hhmmss;
		},

		convertHHMMSStoSeconds(timeHHMMSS) {

			let timeObj = timeHHMMSS.split(':');
			let secondsDuration = (+timeObj[0]) * 60 * 60 + (+timeObj[1]) * 60 + (+timeObj[2]);

			return secondsDuration;
		},

		getTimeline(duration) {

			let resultTimeToReturn = this.convertTimeHHMMSS(this.timeLineSeconds); // get and return last timeline

			//calculate new timeline
			let timeObj = duration.split(':');
			let secondsDuration = (+timeObj[0]) * 60 * 60 + (+timeObj[1]) * 60 + (+timeObj[2]);

			//for first timeline value
			if (this.timeLineSeconds == 0) {

				this.timeLineSeconds = secondsDuration;

				return "00:00:00";
			}

			this.timeLineSeconds = this.timeLineSeconds + secondsDuration;

			return resultTimeToReturn;
		},

		async playPlaylistMix(id, playlistName) {
			this.playMix = true;
			this.songsForMix = [];
			this.timeLineSeconds = 0;
			let intervalsWithTimeline = null;
			let musicIntervals = null;
			let total_duration = null;

			await this.$store.dispatch('audioPlayer/resetPlayerData');

			await PlaylistService.getIntervalsByPlaylist(id)
				.then((res) => {
					intervalsWithTimeline = res;
				})

			for (const interval of intervalsWithTimeline) {

				let timelineString = this.getTimeline(interval.duration);
				interval.timeline = timelineString;
			}
			const musicServiceId = this.currentUser.current_music_streaming_service;
			await PlaylistService.getPlaylistMixView(id)
				.then((res) => {
					musicIntervals = res;
					const map1 = new Map();

					let user_content_rating = (res[0].allow_explicit_content == 0) ? 'clean' : 'explicit';
					total_duration = this.convertHHMMSStoSeconds(res[0].total_duration)
					res.forEach(value => {
						if (map1.has(value.song_id + '_' + value.sections_order + '_' + value.intervals_order + '_' + value.song_inInterval_Order)) {
							if (map1.get(value.song_id + '_' + value.sections_order + '_' + value.intervals_order + '_' + value.song_inInterval_Order).content_rating === user_content_rating) {
							}
							else {
								map1.set(value.song_id + '_' + value.sections_order + '_' + value.intervals_order + '_' + value.song_inInterval_Order, value)
							}
						}
						else {
							map1.set(value.song_id + '_' + value.sections_order + '_' + value.intervals_order + '_' + value.song_inInterval_Order, value)
						}
					})

					musicIntervals = Array.from(map1, ([key, value]) => {
						return value;
					});
					musicIntervals.map(el => this.songsForMix.push(el.streaming_id.toString()));
				})
				.catch((err) => {
					console.log(err)
					this.$store.dispatch('showSnackbarMessage', {
						message: `Mix was not found for this Blueprint`,
						mode: 'fail'
					});
					this.playMix = false
				});

			const music = MusicKit.getInstance();
			await music.stop()
			await music.clearQueue();
			if (musicServiceId == 1){
            await this.set(this.songsForMix)
          	}
          	else {
          	    await this.$store.dispatch('playSongAndPause',this.songsForMix[0]);
          	}
          	const s = this.songsForMix;

			await this.$store.dispatch('audioPlayer/updatePlayerForIntervals', {
				url: null,
				name: playlistName,
				playlistId: id,
				playlistDurationInterval: musicIntervals,
				startSeconds: null,
				endSeconds: total_duration,
				autoplay: false,
				isIntervalPlaying: false,
				intervals: intervalsWithTimeline,
				appleIntervals: musicIntervals,
				songsForMix : s
			});
			this.closeDialog();
			this.playMix = false

		},
		async set(songsForMix) {
			try{

				const music = MusicKit.getInstance();
				await music.mute()
				await music.setQueue({
					songs: songsForMix,
					startPlaying: true
				}
				);
				await music.pause();
	
				return;
			} catch (error) {
          		console.log("error set queue",error)
        	}
		},

		closeDialog() {

			this.$emit('update:showDialog', false);
		},
		getCurrentUser() {	
				return UserService.getOne(this.currentUser.id)
					.then(async (data) => {	
                        const SpotifyDBstoredRefreshToken = data.services.find(sr => sr.service_id == 2)?.token;
				    
                        if (SpotifyDBstoredRefreshToken) {
                            await this.$store.dispatch('updateSpotifyRefreshToken', SpotifyDBstoredRefreshToken); // Set token in Vuex
                            const player = this.$store.getters.spotifyPlayer;
                            await this.$store.dispatch('refreshAccessToken');
                            const spotifyToken = this.$store.getters.spotifyToken;
                            if (!player && spotifyToken)   this.$store.dispatch('initSpotifyPlayer',spotifyToken); // Initialize player with token
                        } 
					})
					.catch((err) => {	
						this.$store.dispatch('showSnackbarMessage',
							{
								message: `${err.message}. Please, try again.`,
								mode: 'fail'
							});
					});
		}
	},

	computed: {
		...mapGetters(
        {
          currentUser: 'auth/getCurrentUser',
        },
    	),
		playLists() {

			return this.playlistsProp;
		}
	},
	async created() {
      await this.getCurrentUser();
  },
}
</script>

<style lang="scss" scoped>
::v-deep .md-dialog-container {
	padding: 0;
	overflow: hidden !important;
	border-radius: 15px !important;
}

.explicit-content {
	font-family: var(--font-family-figtree);
	font-size: var(--font-size-xxs);
	font-weight: bolder;
	font-style: normal;
	border: 1.5px solid var(--nobel);
	color: white;
}

.md-dialog {
	border-radius: 15px !important;

}

.playlist {
	background-color: #262626;
}

.disable-all {
	opacity: 0.5;
}

.playlist-color {
	border-radius: 5px;
	width: 40px;
	height: 39px;
}

.playlist__all-mixes {
	display: flex;
	height: 63px;
	border-bottom: 1px solid #3A3A3A;
	align-items: center;
}

.close-dialog {
	flex: 10;
	text-align: right;
	cursor: pointer;
}
</style>
