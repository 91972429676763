<template>
  <div>
    <div :class="[{ 'more-songs': trackData.interval_songs.length > 1 },'container']">
      <div class="box-2">
        <div class="row">
          <button class="play-icon flex-menu"
                  v-if="trackData.interval_songs.length < 2 && trackData.interval_songs[0].id == intervalWithPlayIconIdProp && isIntervalPlaying &&!fullscreen"
                  @click="currentUser.current_music_streaming_service == 1 ? playInterval(trackData.interval_songs[0]) : playSpotifyInterval(trackData.interval_songs[0])">
            <md-icon class=" ">pause</md-icon>
          </button>

          <button class="play-icon flex-menu" v-else-if="trackData.interval_songs.length < 2 && trackData.interval_songs[0].id != intervalWithPlayIconIdProp && !fullscreen"
                  :id="'play-icon-' + indexProp"
                  @click.stop="currentUser.current_music_streaming_service == 1 ? playInterval(trackData.interval_songs[0]) : playSpotifyInterval(trackData.interval_songs[0])">
            <md-icon class="">play_arrow</md-icon>
          </button>
            <!-- <img class="playing-icon" src="@/assets/images/songSpeaker.png" alt="songSpeaker" v-if="trackData.interval_songs.length < 2 && trackData.interval_songs[0].id == intervalWithPlayIconIdProp && fullscreen" /> -->

          <div class="play-icon" v-if="trackData.interval_songs.length > 1" v-show="!showPlaylistParts"
               @click="expandInterval">
            <md-icon class="expand-button md-fab">keyboard_arrow_down</md-icon>
          </div>

          <div class="play-icon" v-show="showPlaylistParts" @click="showPlaylistParts = !showPlaylistParts">
            <md-icon class="expand-button md-fab">keyboard_arrow_up</md-icon>
          </div>
          <div class="frame-433" v-if="trackData.interval_songs.length < 2">
            <div class="album-art">
            <img class="playing-icon" src="@/assets/images/songSpeaker.png" alt="songSpeaker" v-if="trackData.interval_songs.length < 2 && trackData.interval_songs[0].id == intervalWithPlayIconIdProp && !isIntervalPlaying " />

              <img class="mask-group" :src="imageUrl" alt="Mask group" v-else/>
            </div>
            <div class="frame-431">
              <div class="artist-name figtree-medium-white-12px">
                <span class="explicit-content figtree-normal-white-12px"
                      v-if="SongcontentRating=='explicit' && (trackData.interval_songs[0].song.streamingIds.find((el) => el.content_rating == SongcontentRating))">
                  E
                </span>
                {{ trackData.interval_songs[0].song.title }}
              </div>
              <div class="figtree-normal-white-10px">
                {{ trackData.interval_songs[0].song.artist.name }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="box-3" v-if="showActions && !fullscreen">
        <div class="track__name"
             :id="'genre-select-' + indexProp">
          <!--				v-if="trackData.interval_songs.length < 2"-->
          <md-menu md-align-trigger>
            <md-button md-menu-trigger @click="goNextStepIfTourGuideActive">
              <md-tooltip class="md-theme-light" md-direction="top">{{ trackGenreName }}</md-tooltip>
              <h4 class="figtree-medium-white-14px">{{ trackGenreName }}
                <md-icon class="">keyboard_arrow_down</md-icon>
              </h4>
            </md-button>

            <md-menu-content>
              <md-menu-item
                  v-for="(genre, index) in genresProp.filter(genre=>genre.visibility==1||genre.visibility==2)"
                  :key="index + '_genre'"
                  :id="'genre-select-' + indexProp + '-' + index"
                  @click="changeGenreInterval(genre.id, trackData.interval_songs[0].interval_id, trackData.interval_songs[0].id)"
              >
                {{ genre.name }}
              </md-menu-item>
            </md-menu-content>
          </md-menu>
        </div>
      </div>

      <div class="box-4">
        <div class="figtree-medium-white-14px">{{ trackData.duration }}</div>
      </div>

      <div class="box-5" v-if="showActions && !fullscreen">
        <md-menu md-size="small" md-align-trigger>
          <md-button
              md-menu-trigger
              :id="'volume-menu-button-' + indexProp"
              @click="goNextStepIfTourGuideActive"
          >
            <h4 class="">
              <md-icon>volume_up</md-icon>

              {{ trackData.volume }}%
            </h4>
          </md-button>

          <md-menu-content>
            <md-menu-item
                v-for="(volume, index) in [25, 50, 75, 100]"
                :key="index + '_volume'"
                :id="'volume-level-select-' + indexProp + '-' + index"
                @click="changeIntervalVolume(volume, trackData.id)"
            >
              {{ volume }}%
            </md-menu-item>
          </md-menu-content>
        </md-menu>
      </div>

      <div class="box-6">
        <div style="align-items: center;">
          <button :disabled="fullscreen" @click="showFlexSelect($event, indexProp)" class="row-3 flex-menu">
            <flexLevels :className="flexClass[indexProp]" :key="'flex_' + indexProp" :id="'flex_' + indexProp"/>
            <FlexSelect :className="'sections'" :key="'flexSelect_' + indexProp" :id="'flexSelect_' + indexProp"
                        :index="indexProp"
                        style="display:none" @changeFlex="setFlex"/>

          </button>
        </div>
      </div>


      <div class="box-7" v-if="showActions && !fullscreen">
        <div title="like" style="cursor: pointer" @click="likeSong(trackData.interval_songs[0])"
             v-if="trackData.interval_songs.length < 2">
          <md-icon>thumb_up</md-icon>
        </div>
      </div>


      <div class="box-8" v-if="showActions && !fullscreen">
        <div title="dislike/replace" style="cursor: pointer" @click="dislikeSong(trackData.interval_songs[0])"
             v-if="trackData.interval_songs.length < 2">
          <md-icon>thumb_down</md-icon>
        </div>
      </div>


      <div class="box-9" v-if="showActions && !fullscreen">
        <div title="replace" style="cursor: pointer" @click="shuffleSong(trackData.interval_songs[0])"
             v-if="trackData.interval_songs.length < 2">
          <md-icon>find_replace</md-icon>
        </div>
      </div>

      <div class="handle box-11" v-if=" !fullscreen">
        <md-icon >drag_indicator</md-icon>
      </div>

      <div class="box-10" v-if="!showActions && !fullscreen && trackData.interval_songs.length < 2">
        <div @click="showInterval(trackData.interval_songs[0],imageUrl,trackGenreName,flexClass[indexProp])">
          
          <md-icon style="color: white">more_vert</md-icon>
        </div>
      </div>


    </div>


    <!--   <div > -->
    <draggable v-model="listIntervals"  @start="drag=true" @end="drag=false" v-if="trackData.interval_songs.length > 1 && showPlaylistParts&&!fullscreen" group="b" handle=".handle"  :scroll="true" :scroll-sensitivity="200">
      <div 
           v-for="(oneSong, index) in trackData.interval_songs"
           :class="[ 'playing','container']" :id="'inner-interval_' + indexProp + '_' + index"
           :key="index + 'trackData'">

        <div style="flex:3"></div>
        <div class="box-2">
          <div class="row">
            <button class="play-icon flex-menu" v-if="oneSong.id == intervalWithPlayIconIdProp && isIntervalPlaying && !fullscreen"
                    @click="pauseInterval(oneSong)">
              <md-icon class=" ">pause</md-icon>
            </button>

            <button class="play-icon flex-menu" v-else-if="!fullscreen && oneSong.id != intervalWithPlayIconIdProp"
                    :id="'play-icon-inner-' + indexProp + '-' + index"
                    @click.stop="currentUser.current_music_streaming_service == 1 ? playInterval(oneSong) : playSpotifyInterval(oneSong)">
              <md-icon class=" ">play_arrow</md-icon>
            </button>

            

            <!-- <button class="play-icon flex-menu" v-if="oneSong.id == intervalWithPlayIconIdProp"
                    @click="pauseInterval(oneSong)">
              <md-icon class=" ">add</md-icon>
            </button> -->
            <div class="frame-433">
              <div class="album-art">
                <img class="playing-icon" src="@/assets/images/songSpeaker.png" alt="songSpeaker" v-if="oneSong.id == intervalWithPlayIconIdProp  && !isIntervalPlaying" />
                <img class="mask-group" :src="imageUrls[index]" alt="Mask group" v-else/>
              </div>
              <div class="frame-431">
                <div class="artist-name figtree-medium-white-12px">
                  <span class="explicit-content figtree-normal-white-12px"
                        v-if="SongcontentRating=='explicit' && (oneSong.song.streamingIds.find((el) => el.content_rating == SongcontentRating))">
                    E
                  </span>
                  {{ oneSong.song.title }}
                </div>
                <div class="figtree-normal-white-10px">
                  {{ oneSong.song.artist.name }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="box-3" v-if="showActions && !fullscreen">
          <div class="track__name" :id="'genre-select-' + indexProp">
            <!--				v-if="trackData.interval_songs.length < 2"-->
            <md-menu md-align-trigger>
              <md-button md-menu-trigger @click="goNextStepIfTourGuideActive">
                <md-tooltip class="md-theme-light" md-direction="top">{{ getGenreName(oneSong.genre_id) }}</md-tooltip>
                <h4 class="figtree-medium-white-14px">{{ getGenreName(oneSong.genre_id) }}
                  <md-icon class="">keyboard_arrow_down</md-icon>
                </h4>
              </md-button>

              <md-menu-content>
                <md-menu-item
                    v-for="(genre, indexInnerGenre) in genresProp.filter(genre=>genre.visibility==1||genre.visibility==2)"
                    :key="indexInnerGenre + '_genreOneSong'"
                    :id="'genre-select-' + indexProp + '-' + index + '-' + genre.name"
                    @click="changeGenre(genre.id, oneSong.id)">
                  {{ genre.name }}
                </md-menu-item>
              </md-menu-content>
            </md-menu>
          </div>
        </div>

        <div class="box-4">
          <div class="figtree-medium-white-14px">{{ oneSong.song_duration | convertTimeHHMMSS }}</div>
        </div>


        <div class="box-5"></div>

        <div class="box-6">

        </div>


        <div class="box-7" v-if="showActions && !fullscreen">
          <div title="like" style="cursor: pointer" @click="likeSong(oneSong)">
            <md-icon>thumb_up</md-icon>
          </div>
        </div>


        <div class="box-8" v-if="showActions && !fullscreen">
          <div title="dislike/replace" style="cursor: pointer" @click="dislikeSong(oneSong)">
            <md-icon>thumb_down</md-icon>
          </div>
        </div>


        <div class="box-9" v-if="showActions && !fullscreen">
          <div title="replace" style="cursor: pointer" @click="shuffleSong(oneSong,index)">
            <md-icon>find_replace</md-icon>
          </div>
        </div>

         <div class="handle box-11" v-if=" !fullscreen">
        <md-icon >drag_indicator</md-icon>
      </div>
        <div class="box-10" v-if="!showActions && !fullscreen">
          <div @click="showInterval(oneSong,imageUrls[index],getGenreName(oneSong.genre_id),flexClass[indexProp])">

            <md-icon style="color: white">more_vert</md-icon>
          </div>
        </div>

      </div>
    </draggable>
    <div v-if="trackData.interval_songs.length > 1 && showPlaylistParts && fullscreen"
           v-for="(oneSong, index) in trackData.interval_songs"
           :class="[ 'playing','container']" :id="'inner-interval_' + indexProp + '_' + index"
           :key="index + 'trackData'">

        <div style="flex:3"></div>
        <div class="box-2">
          <div class="row">
            <button class="play-icon flex-menu" v-if="oneSong.id == intervalWithPlayIconIdProp && isIntervalPlaying && !fullscreen"
                    @click="pauseInterval(oneSong)">
              <md-icon class=" ">pause</md-icon>
            </button>

            <button class="play-icon flex-menu" v-else-if="!fullscreen && oneSong.id != intervalWithPlayIconIdProp"
                    :id="'play-icon-inner-' + indexProp + '-' + index"
                    @click.stop="playSpotifyInterval(oneSong)">
              <md-icon class=" ">play_arrow</md-icon>
            </button>

            

            <!-- <button class="play-icon flex-menu" v-if="oneSong.id == intervalWithPlayIconIdProp"
                    @click="pauseInterval(oneSong)">
              <md-icon class=" ">add</md-icon>
            </button> -->
            <div class="frame-433">
              <div class="album-art">
                <img class="playing-icon" src="@/assets/images/songSpeaker.png" alt="songSpeaker" v-if="oneSong.id == intervalWithPlayIconIdProp  && !isIntervalPlaying" />
                <img class="mask-group" :src="imageUrls[index]" alt="Mask group" v-else/>
              </div>
              <div class="frame-431">
                <div class="artist-name figtree-medium-white-12px">
                  <span class="explicit-content figtree-normal-white-12px"
                        v-if="SongcontentRating=='explicit' && (oneSong.song.streamingIds.find((el) => el.content_rating == SongcontentRating))">E</span>
                  {{ oneSong.song.title }}
                </div>
                <div class="figtree-normal-white-10px">
                  {{ oneSong.song.artist.name }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="box-3" v-if="showActions && !fullscreen">
          <div class="track__name" :id="'genre-select-' + indexProp">
            <!--				v-if="trackData.interval_songs.length < 2"-->
            <md-menu md-align-trigger>
              <md-button md-menu-trigger @click="goNextStepIfTourGuideActive">
                <md-tooltip class="md-theme-light" md-direction="top">{{ getGenreName(oneSong.genre_id) }}</md-tooltip>
                <h4 class="figtree-medium-white-14px">{{ getGenreName(oneSong.genre_id) }}
                  <md-icon class="">keyboard_arrow_down</md-icon>
                </h4>
              </md-button>

              <md-menu-content>
                <md-menu-item
                    v-for="(genre, indexInnerGenre) in genresProp.filter(genre=>genre.visibility==1||genre.visibility==2)"
                    :key="indexInnerGenre + '_genreOneSong'"
                    :id="'genre-select-' + indexProp + '-' + index + '-' + genre.name"
                    @click="changeGenre(genre.id, oneSong.id)">
                  {{ genre.name }}
                </md-menu-item>
              </md-menu-content>
            </md-menu>
          </div>
        </div>

        <div class="box-4">
          <div class="figtree-medium-white-14px">{{ oneSong.song_duration | convertTimeHHMMSS }}</div>
        </div>


        <div class="box-5"></div>

        <div class="box-6">

        </div>


        <div class="box-7" v-if="showActions && !fullscreen">
          <div title="like" style="cursor: pointer" @click="likeSong(oneSong)">
            <md-icon>thumb_up</md-icon>
          </div>
        </div>


        <div class="box-8" v-if="showActions && !fullscreen">
          <div title="dislike/replace" style="cursor: pointer" @click="dislikeSong(oneSong)">
            <md-icon>thumb_down</md-icon>
          </div>
        </div>


        <div class="box-9" v-if="showActions && !fullscreen">
          <div title="replace" style="cursor: pointer" @click="shuffleSong(oneSong,index)">
            <md-icon>find_replace</md-icon>
          </div>
        </div>


        <div class="box-10" v-if="!showActions && !fullscreen">
          <div @click="showInterval(oneSong,imageUrls[index],getGenreName(oneSong.genre_id),flexClass[indexProp])">

            <md-icon style="color: white">more_vert</md-icon>
          </div>
        </div>

      </div>
    <EditIntervalDialog
        :showDialog.sync="showEditIntervalDialog"
        :key="intervalDialogKey"
        :track-data="intervalDialogTrackData"
        :image-url="intervalDialogImageUrl"
        :genre-name-prop="intervalDialogGetGenreName"
        :genres-prop="genresProp"
        :flex-class="intervalDialogFlexClass"
        :indexProp="indexProp"
        :volume="trackData.volume"
        :isInterval="trackData.interval_songs.length === 1"
        @likeSong="likeSong"
        @dislikeSong="dislikeSong"
        @shuffleSong="shuffleSong"
        @changeGenrePhone="changeGenrePhone"
        @changeIntervalVolume="changeIntervalVolume"
        @playInterval="playInterval"
        @pauseInterval="pauseInterval"
        @changeAppleSong="appleSong"
        @playSpotifyInterval="playSpotifyInterval"
    />
  </div>
</template>

<script>
import IntervalSongService from '../../services/interval-song.service';
import IntervalService from '../../services/interval.service';
import SongService from '@/services/song.service'
import {mapGetters} from "vuex";
import draggable from 'vuedraggable'
import FlexSelect from '@/components/TrainerDashboard/FlexSelect';
import flexLevels from "@/components/TrainerDashboard/flexLevels";
import helpers from "../../store/helpers";
import EditIntervalDialog from "../../components/TrainerDashboard/EditIntervalDialog";
import {CheckWindowWidthMixin} from '../../mixins/CheckWindowWidthMixin';
import {MusicKitHelperMixin} from '../../mixins/MusicKitHelperMixin';
import { AppleSearchMixin } from "../../mixins/AppleSearchMixin";


let intervalflag;
export default {
  name: 'TrainerDashboardBluePrintTrackPlaylist',
  mixins: [CheckWindowWidthMixin, MusicKitHelperMixin,AppleSearchMixin],

  components: {
    flexLevels,
    FlexSelect,
    draggable,
    EditIntervalDialog
  },

  props: {
    trackData: {
      type: [Object, Array]
    },

    genresProp: {
      type: [Array],
      default: () => []

    },

    timeLineProp: {
      type: String,
      default: null
    },

    intervalWithPlayIconIdProp: {
      type: Number,
      default: null,
    },

    indexProp: {
      type: Number,
      default: null,
    },
    contentRating: {
      type: Boolean,
      default: false
    },
    totalListIntervals: {
      type: [Array],
      default: () => []
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
  },

  data: () => ({
    flexClass: [],
    showPlaylistParts: false,
    parentSongIsPlaying: false,
    showIntervalNameInputId: false,
    isPLaying: false,
    isCleared: false,
    music: null,
    SongcontentRating: '',
    imageUrl: null,
    imageUrls: [],
    intervalDialogKey: 0,
    showEditIntervalDialog: false,
    intervalDialogTrackData: {},
    intervalDialogImageUrl: '',
    intervalDialogGetGenreName: '',
    intervalDialogFlexClass: ''
  }),

  computed: {
    ...mapGetters(
        {
          isIntervalPlaying: 'audioPlayer/getIsIntervalPlaying',
        },
    ),

    listIntervals: {

      get() {

        return this.trackData.interval_songs;
      },

      async set(intervalList) {
        let periodId = this.$route.params.id;
        for (let index = 0; index < intervalList.length; index++)  {
          const  interval = intervalList[index]
          if (interval.attributes) {
            let indexToBeReplaced,intervalSongId;
            if (intervalList[index + 1]) {
                indexToBeReplaced = this.indexProp + '_' + index;
                intervalSongId =  intervalList[index + 1].id;
            }
            else{
              indexToBeReplaced = this.indexProp + '_' +Number(index-1);
              intervalSongId =   intervalList[index-1].id;
            }
            
            await this.appleSong(interval, intervalSongId,false,indexToBeReplaced)
            return;
          }
          interval.order = index;
        };

        let dataForOrder = intervalList.map(({id, order}) => ({id, order}));

        IntervalService.reorderIntervalsMoreThenTwoMin({data: dataForOrder, id: periodId})
            .then(() => {
              this.closeAppleAudioPlayer(this.currentUser.current_music_streaming_service);
              this.$emit('refreshAllIntervals');
              this.$store.dispatch('showSnackbarMessage', {
                message: 'Playlist successfully reordered!',
                duration: 4000,
                mode: 'success'
              });
            })
            .catch(() => {

              this.$store.dispatch('showSnackbarMessage', {
                message: 'Failed. Please, try again.',
                duration: 5000,
                mode: 'fail'
              });
            });

        this.trackData.interval_songs = intervalList;
      }
    },

    track() {

      return this.trackData;
    },

    trackGenreName() {

      if (this.isIntervalHavingDifferentGenres() === true) {
        return 'Combo'
      }

      for (const genre of this.genresProp) {
        if (genre.id === this.trackData.interval_songs[0].genre_id) {
          return genre.name;
        }
      }
    },

    ...mapGetters(
        {
          userRole: 'auth/currentUserRole',
          currentUser: 'auth/getCurrentUser',
          showTourGuide: 'getIsTourGuideActive'
        },
    ),

  },

  methods: {
    changeGenrePhone(genreId, intervalId, trackDataIntervalSongId, isInterval) {
      if (isInterval) {
        this.changeGenreInterval(genreId, intervalId, trackDataIntervalSongId)
      } else {
        this.changeGenre(genreId, trackDataIntervalSongId)
      }
    }
    ,
    async showInterval(IntervalData, imageUrl, genreName, flexClass) {
      await this.setIntervalDialogData(IntervalData, imageUrl, genreName, flexClass);
      this.intervalDialogKey++;
      this.showEditIntervalDialog = true;
    },

    setIntervalDialogData(IntervalData, imageUrl, genreName, flexClass) {
      this.intervalDialogTrackData = IntervalData
      this.intervalDialogImageUrl = imageUrl
      this.intervalDialogFlexClass = flexClass
      this.intervalDialogGetGenreName = genreName
    },


    convertHHMMSStoHMSeconds(timeHHMMSS) {
      const timeObj = timeHHMMSS.split(':');
      let res = (Number(timeObj[0]) !== 0 ? Number(timeObj[0]) + ' hrs ' : '') + (Number(timeObj[1]) !== 0 ? Number(timeObj[1]) + ' mins ' : '') + (Number(timeObj[2]) !== 0 ? Number(timeObj[2]) + ' Secs ' : '');

      return res;
    },


    showFlexSelect(event, index) {
      if (document.querySelector('#flexSelect_' + index).style.display == 'block') {

        document.querySelector('#flexSelect_' + index).style.display = 'none'
      } else (document.querySelector('#flexSelect_' + index).style.display = 'block');
    },

    setFlex(val, index, setFlexFirstTime) {
      if (!setFlexFirstTime) {

        this.changeFlexLevelInterval(val, this.trackData, this.trackData.interval_songs[0].id);
      }
      switch (val) {
        case 1:
          this.flexClass[index] = 'stretch';
          break;
        case 2:
          this.flexClass[index] = 'warm-up';
          break;
        case 3:
          this.flexClass[index] = 'stretch-training-1';
          break;
        case 4:
          this.flexClass[index] = 'cardio-1';
          break;
        case 5:
          this.flexClass[index] = 'sprint-1';
          break;
        default:
          break;
      }
      this.$forceUpdate();


    },
    isIntervalHavingDifferentGenres() {

      let resultDifferentGenres = false;

      if (this.trackData.interval_songs.length > 1) {

        let tempGenreId = this.trackData.interval_songs[0].genre_id;

        this.trackData.interval_songs.forEach((interval) => {

          if (tempGenreId != interval.genre_id) {
            resultDifferentGenres = true;
          }
        });
      }

      return resultDifferentGenres;
    },

    goNextStepIfTourGuideActive() {

      if (this.showTourGuide) {
        setTimeout(() => {

          if (this.showTourGuide) {
            this.$nextTick(() => {

              this.$tours['myTour'].nextStep();
            })
          }
        }, 200);

      }
    },

    expandInterval() {

      this.showPlaylistParts = !this.showPlaylistParts;
      this.goNextStepIfTourGuideActive();
    },

    getGenreName(genre_id) {

      for (const genre of this.genresProp) {

        if (genre.id == genre_id) {
          return genre.name;
        }
      }
    },

    openTheEdit(id) {

      this.$emit('openEdit', id);
    },

    removeInterval(intervalId) {

      IntervalService.deleteInterval(intervalId)
          .then(() => {

            this.$emit('refreshAllIntervals');

            this.$store.dispatch('showSnackbarMessage', {
              message: 'The interval was successfully deleted.',
              duration: 4000,
              mode: 'success'
            });
          })
          .catch((err) => {

            this.$store.dispatch('showSnackbarMessage', {
              message: `${err.message}. Please, try again.`,
              duration: 4000,
              mode: 'fail'
            });
          });
    },

    async pauseInterval(intervalToPause) {
      this.music.pause()
      this.$emit('setIntervalIconToPlaying', null);
    },

    async playInterval(intervalToPlay) {
      var startSeconds = intervalToPlay.song_start_sec;
      var endSeconds = intervalToPlay.song_end_sec;
      let songStreaming_id;
      if (intervalToPlay.song.streamingIds.length == 1) {
        songStreaming_id = intervalToPlay.song.streamingIds[0].streaming_id;
      } else {

        songStreaming_id = (intervalToPlay.song.streamingIds.find((el) => el.content_rating == this.SongcontentRating)).streaming_id;
      }

      if (this.music.nowPlayingItem && (this.music.nowPlayingItem.id != songStreaming_id || !this.isIntervalPlaying)) {
        await this.closeAppleAudioPlayer(this.currentUser.current_music_streaming_service);
      }

      if (this.music.nowPlayingItem && Number(this.music.nowPlayingItem.id) == songStreaming_id && this.music.isPlaying) {
        this.music.pause();
        this.$emit('setIntervalIconToPlaying', null);
      } else if (this.music.queueIsEmpty || this.music.nowPlayingItem.id != songStreaming_id) {
        this.music.setQueue({song: songStreaming_id}).then(() => {
          this.music.volume = 1;
          this.music.play().then(() => {
            this.music.seekToTime(intervalToPlay.song_start_sec)
          }).catch((e) => {
            if (e.errorCode === "CONTENT_RESTRICTED") {
              this.$store.dispatch('showSnackbarMessage', {
                message: "Please log into your Apple Music account and allow Explicit music.",
                duration: 8000,
                mode: 'fail'
              });
            } else {
              this.$store.dispatch('showSnackbarMessage', {
                message: "Something went wrong. Try again.",
                duration: 4000,
                mode: 'fail'
              });
            }
          });
        })

        this.$emit('setIntervalIconToPlaying', intervalToPlay.id);
      } else {
        this.music.play();
        this.$emit('setIntervalIconToPlaying', intervalToPlay.id);

      }
      // this.StopIntervalAtEndSec(endSeconds)


      this.goNextStepIfTourGuideActive();
    },
    async playSpotifyInterval(intervalToPlay) {
      let songStreaming_id;
      let SpotifyCurrentStatus = await this.$store.dispatch('currentState');
      const spotifyNowPlayingUri = SpotifyCurrentStatus?.track_window.current_track.uri;
      const isSpotifyNowPlaying  = !(SpotifyCurrentStatus?.paused);
      if (intervalToPlay.song.streamingIds.length == 1) {
        songStreaming_id = intervalToPlay.song.streamingIds[0].streaming_id;
      } else {
        
        songStreaming_id = (intervalToPlay.song.streamingIds.find((el) => el.content_rating == this.SongcontentRating)).streaming_id;
      }
      
      if (SpotifyCurrentStatus && isSpotifyNowPlaying && (spotifyNowPlayingUri != songStreaming_id || !this.isIntervalPlaying)) {
        await this.closeAppleAudioPlayer(this.currentUser.current_music_streaming_servicethis);
        this.$emit('setIntervalIconToPlaying', null);
      }
      if (SpotifyCurrentStatus && isSpotifyNowPlaying &&  spotifyNowPlayingUri == songStreaming_id) {
        await this.$store.dispatch('pause');
        this.$emit('setIntervalIconToPlaying', null);
      } else if (!SpotifyCurrentStatus || (SpotifyCurrentStatus && spotifyNowPlayingUri != songStreaming_id)) {
        await this.$store.dispatch('playSong',songStreaming_id).then(async() => {
          await this.$store.dispatch('seek',intervalToPlay.song_start_sec)
          await this.$store.dispatch('setVolume',1)
          this.$emit('setIntervalIconToPlaying', intervalToPlay.id);
        }).catch((e) => {
          this.$emit('setIntervalIconToPlaying', null);
            if (e.errorCode === "CONTENT_RESTRICTED") {
              this.$store.dispatch('showSnackbarMessage', {
                message: "Please log into your Apple Music account and allow Explicit music.",
                duration: 8000,
                mode: 'fail'
              });
            } else {
              this.$store.dispatch('showSnackbarMessage', {
                message: "Something went wrong. Try again.",
                duration: 4000,
                mode: 'fail'
              });
            }
          });

      } else {
        await this.$store.dispatch('togglePlay')
        this.$emit('setIntervalIconToPlaying', intervalToPlay.id);

      }
      // this.StopIntervalAtEndSec(endSeconds)


      this.goNextStepIfTourGuideActive();
    },

    StopIntervalAtEndSec(endSeconds) {
      if (intervalflag) {
        clearInterval(intervalflag);
      }
      intervalflag = setInterval(() => {

        if (endSeconds <= this.music.currentPlaybackTime) {
          clearInterval(intervalflag)
          this.music.pause()
          this.$emit('setIntervalIconToPlaying', null);
        }
      }, 1000);
    },

    changeFlexLevelInterval(energySelectedLevel, trackData, intervalId) {

      energySelectedLevel = energySelectedLevel.toString();
      let splittedFlex = energySelectedLevel.split('-')[0];

      let energyToSend = Number(splittedFlex);

      let changeSongObj = {
        energy: energyToSend
      };

      if (this.showTourGuide) {

        IntervalService.editSongIntervalForTourGuide(changeSongObj, trackData.id)
            .then(() => {

              this.$emit('refreshAllIntervals', intervalId);

              this.$store.dispatch('showSnackbarMessage', {
                message: 'Song successfully changed!',
                duration: 4000,
                mode: 'success'
              });
            })

        this.goNextStepIfTourGuideActive();

        return;
      }

      IntervalService.editSongInterval(changeSongObj, trackData.id)
          .then(() => {

            this.$emit('refreshAllIntervals', intervalId);
            this.closeAppleAudioPlayer(this.currentUser.current_music_streaming_service);
            this.$store.dispatch('showSnackbarMessage', {
              message: 'Song successfully changed!',
              duration: 4000,
              mode: 'success'
            });
          })
          .catch((err) => {

            // this.$store.dispatch('showSnackbarMessage', {
            // 	message: `${err.message}. Please, try again.`,
            // 	duration: 4000,
            // 	mode: 'fail'
            // });
          });

    },

    changeFlexLevel(energySelectedLevel, trackData) {

      let changeSongObj = {
        energy: energySelectedLevel
      }

      IntervalService.changeSong(changeSongObj, trackData.id)
          .then(() => {

            this.$emit('refreshAllIntervals');

            this.$store.dispatch('showSnackbarMessage', {
              message: 'Song successfully changed!',
              duration: 4000,
              mode: 'success'
            });
          })
          .catch((err) => {

            // this.$store.dispatch('showSnackbarMessage', {
            // 	message: `${err.message}. Please, try again.`,
            // 	duration: 4000,
            // 	mode: 'fail'
            // });
          });
    },

    changeIntervalName(intervalId, newIntervalName) {

      IntervalService.editSongInterval({name: newIntervalName, is_tour_guide: this.showTourGuide}, intervalId)
          .then(() => {

            this.$emit('refreshAllIntervals');
            this.goNextStepIfTourGuideActive();

            this.$store.dispatch('showSnackbarMessage', {
              message: 'Name successfully changed!',
              duration: 4000,
              mode: 'success'
            });
          });
    },

    changeIntervalVolume(volumeValue, intervalId) {

      IntervalService.editSongInterval({volume: volumeValue, is_tour_guide: this.showTourGuide}, intervalId)
          .then((res) => {

            this.$store.dispatch('showSnackbarMessage', {
              message: 'Volume successfully changed!',
              duration: 4000,
              mode: 'success'
            });
            this.$emit('refreshAllIntervals');
            this.closeAppleAudioPlayer(this.currentUser.current_music_streaming_service);
            this.goNextStepIfTourGuideActive();
          });
    },

    changeGenreInterval(genreSelectedId, songToChangeId, intervalId) {

      let changeSongObj = {
        genre_id: genreSelectedId,
      }

      IntervalService.editSongInterval(changeSongObj, songToChangeId)
          .then(() => {

            this.$emit('refreshAllIntervals', intervalId);
            this.closeAppleAudioPlayer(this.currentUser.current_music_streaming_service);
            this.$store.dispatch('showSnackbarMessage', {
              message: 'Song successfully changed!',
              duration: 4000,
              mode: 'success'
            });
          })
          .catch((err) => {

            // this.$store.dispatch('showSnackbarMessage', {
            // 	message: `${err.message}. Please, try again.`,
            // 	duration: 4000,
            // 	mode: 'fail'
            // });
          });

      this.goNextStepIfTourGuideActive();
    },

    changeGenre(genreSelectedId, songToChangeId) {

      let changeSongObj = {
        genre_id: genreSelectedId,
      }

      if (this.showTourGuide) {

        changeSongObj.is_tour_guide = true;
      }

      IntervalSongService.update(changeSongObj, songToChangeId)
          .then((res) => {

            this.$emit('refreshAllIntervals', res.id);

            this.$store.dispatch('showSnackbarMessage', {
              message: 'Song successfully changed!',
              duration: 4000,
              mode: 'success'
            });

            this.goNextStepIfTourGuideActive();
          })
    },

    likeSong(trackData) {
      SongService.changeSongData({like: 1}, trackData.id)
          .then(() => {

            this.$emit('refreshAllIntervals');

            this.$store.dispatch('showSnackbarMessage', {
              message: 'You liked the song',
              duration: 4000,
              mode: 'success'
            });
          })
          .catch((err) => {

            this.$store.dispatch('showSnackbarMessage', {
            	message: `${err.message}. Please, try again.`,
            	duration: 4000,
            	mode: 'fail'
            });
          });
    },

    dislikeSong(trackData) {

      SongService.changeSongData({dislike: 1}, trackData.id)
          .then((res) => {

            this.$emit('refreshAllIntervals', res.id);

            this.$store.dispatch('showSnackbarMessage', {
              message: 'You disliked the song',
              duration: 4000,
              mode: 'success'
            });
          })
          .catch((err) => {

            this.$store.dispatch('showSnackbarMessage', {
              message: `${err.message}. Please, try again.`,
              duration: 4000,
              mode: 'fail'
            });
          });
    },

    shuffleSong(trackData, index = 0) {

      SongService.changeSongData({change_song: 1}, trackData.id)
          .then(async (res) => {
           
            this.$emit('refreshAllIntervals', res.id);
            this.closeAppleAudioPlayer(this.currentUser.current_music_streaming_service);
            this.$emit('setIntervalIconToPlaying', null);
            this.goNextStepIfTourGuideActive();

            this.$store.dispatch('showSnackbarMessage', {
              message: 'Song successfully changed!',
              duration: 4000,
              mode: 'success'
            });
          })
          .catch((err) => {

            let errorMessage = err.response.data ? err.response.data: `${err.message}. Please, try again.`;
            
            this.$store.dispatch('showSnackbarMessage', {
            	message: errorMessage,
            	duration: 4000,
            	mode: 'fail'
            });
          });
    },

    editSong(trackData) {

      this.$emit('openEdit', trackData);
    },
    async play(row) {
      if (!this.music.isAuthorized && this.music.queueIsEmpty) {
        try {
          await this.music.authorize();
        } catch (e) {
          console.log('error', e)
        }
      }
      if (!this.isPLaying) {
        this.music.setQueue({song: row.streaming_id}).then(() => {
          this.music.volume = 1;
          this.music.play().then(() => {
            this.isPLaying = true;
          });
        })
      } else {
        this.music.pause().then(() => {
          this.isPLaying = false;
        });
      }
    },

    async getURlForMultiSongsInterval(trackIntervalSongs) {
      let trackStreamingID;
      for (let i=0 ;i< trackIntervalSongs.length ;i++) {
        const IntervalSong = trackIntervalSongs[i]
        trackStreamingID = IntervalSong.song.streamingIds[0].streaming_id;

        this.imageUrls[i]= this.currentUser.current_music_streaming_service == 1 ? 
                                    await this.getArtWork(trackStreamingID, 300) : 
                                    await this.$store.dispatch('fetchSpotifyArtWork',trackStreamingID,300);
        if(!this.imageUrls[i]){
          this.shuffleSong(trackIntervalSongs[i])
        }
      }
      this.$forceUpdate();

    }
  },

  watch: {
    async trackData(value) {
      if (this.trackData.interval_songs.length > 1) {
        await this.getURlForMultiSongsInterval(this.trackData.interval_songs);
      }
      else {

        const trackStreamingID = this.trackData.interval_songs[0].song.streamingIds[0].streaming_id;
        this.imageUrl = this.currentUser.current_music_streaming_service == 1 ? 
                                    await this.getArtWork(trackStreamingID, 300) : 
                                    await this.$store.dispatch('fetchSpotifyArtWork',trackStreamingID,300);
      }
      this.$forceUpdate();

      this.setFlex(this.trackData.energy, this.indexProp, 1)
    },

    showIntervalNameInputId(value) {

      this.goNextStepIfTourGuideActive();
    }

  },

  filters: {

    removeHH(val) {

      return val.substr(3);
    },

    convertTimeHHMMSS(val) {

      let hhmmss = new Date(val * 1000).toISOString().substr(11, 8);

      return hhmmss;
    },

    songNameAndArtist(val) {

      return val.song.title + ' - ' + val.song.artist.name;
    },

    stringLimitTablet(val, size) {

      val = val.toString();

      if (val.length > size && (window.innerWidth < 1400 && window.innerWidth > 766)) {

        return val.substr(0, size) + '...';
      }

      return val;
    },

    stringLimitDesktop(val, size) {

      val = val.toString();

      if (val.length > size && window.innerWidth > 1400) {

        return val.substr(0, size) + '...';
      }

      return val;
    },
    // getGenreName(genre_id) {
    //
    // 	for (const genre of this.genresProp) {
    //
    // 		if (genre.id == genre_id) {
    // 			return genre.name;
    // 		}
    // 	}
    // }
  },

  async created() {
    this.showIntervalNameInputId = !!this.trackData.name;
    this.music = MusicKit.getInstance();
    this.SongcontentRating = (this.contentRating) ? 'explicit' : 'clean';

    this.setFlex(this.trackData.energy, this.indexProp, 1)

    if (this.trackData.interval_songs.length > 1) {
      await this.getURlForMultiSongsInterval(this.trackData.interval_songs);
    } else {

      const trackStreamingID = this.trackData.interval_songs[0].song.streamingIds[0].streaming_id;
      this.imageUrl = this.currentUser.current_music_streaming_service == 1 ? 
                                    await this.getArtWork(trackStreamingID, 300) : 
                                    await this.$store.dispatch('fetchSpotifyArtWork',trackStreamingID,300);

                                    
      if (!this.imageUrl){
          this.shuffleSong(this.trackData.interval_songs[0])
      }
    }

  },
  beforeDestroy() {
    if (this.isIntervalPlaying) {
      clearInterval(intervalflag);
      this.music.stop();
      this.music.clearQueue();
    }

  },


}
</script>

<style lang="scss" scoped>
.playing {
  background: #2F2F2F  !important;
  // border-color: rgb(27 27 27) !important;
}

.track {
  width: 100%;
  height: 50px;
  display: flex;
  border-radius: 3px;
  background: $color-grey-light-transparent;
  transition: all .2s ease-in;
  backface-visibility: hidden;
  z-index: 1;
  position: relative;
  border: 1px solid $color-white;

  @include respond(medium) {
    padding-left: 1rem;
    flex-direction: column;
    height: 100%;
    margin-top: 3rem;
  }

  &:not(:first-of-type) {

    @include respond(medium) {
      //margin-top: -24rem;
    }
  }

  &:hover {
    z-index: 10000;
    transform: scale(0.98, 0.98);
  }

  &__color {
    width: 10rem;
    height: 100%;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .2);
    background-color: $color-accent-red;

    @include respond(medium) {
      width: 6rem;
      position: absolute;
      left: 0;
    }
  }

  .timeline-data {
    padding-left: 20px;

    @include respond(medium) {
      padding-left: 0;
    }
  }

  &__name {

    text-align: left;
    font-size: 1.5rem;
    font-weight: 400;
    color: $color-white;
    line-height: 50px;

    @include respond(medium) {
      //border-bottom: 1px solid $color-grey-light;
      margin: 0;
      min-height: 50px;
    }

    .md-button {
      min-width: 50px;
    }

    .md-field.md-focused .md-input, .md-field.md-focused .md-textarea, .md-field.md-has-value .md-input, .md-field.md-has-value .md-textarea {
      font-size: 14px;
    }

    .interval-name-change-field {

      @include respond(medium) {
        justify-content: flex-start !important;
      }
    }
  }

  .inner-duration {
    padding-left: 15px;

    @include respond(medium) {
      padding-left: 0;
    }
  }

  //.inner-button-group {
  //	padding-left: 20px;
  //
  //	@include respond(small) {
  //		padding-left: 0;
  //	}
  //}

  &__time,
  &__tags,
  &__bits {
    min-width: 15%;
    display: flex;
    align-items: center;

    @include respond(medium) {
      height: 6rem;
      padding: .8rem 0 .8rem 1.6rem;
      border-bottom: 1px solid $color-grey-light;
    }
  }

  &__tags {
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    max-width: 300px;
    width: auto;
    position: relative;
    padding-right: 40px;

    &::-webkit-scrollbar {
      height: 2px;
    }

    -ms-overflow-style: none;

  }

  &__tag {
    font-weight: 600;
    font-size: 1.4rem;
    text-transform: uppercase;
    color: $color-light-grey;
    margin-right: 1rem;
    min-width: fit-content;
    cursor: pointer;
    padding: 3px 5px;

    &:hover {
      box-shadow: 1px 2px 4px rgba(0, 0, 0, .2);
      border: 2px solid $color-danger;
      padding: 13px;
      background: rgba($color-danger, .2);
    }
  }

  &__tag-input {
    position: sticky;
    right: -40px;
    background: #fff;
  }

  &__actions {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;

    @include respond(medium) {
      height: 6rem;
      width: 100%;
      margin: 10px;
    }


    .md-button:hover .md-icon {
      color: $color-danger;
    }

    &__more {
      border-left: 1px solid $color-white;
      padding: 0 30px;

      @include respond(medium) {
        border-left: 0;
        padding: 0;
      }
    }
  }

  .expand-button {
    background-color: $color-accent-purple;
    color: black;
    border-radius: 0 !important;
  }
}

.md-layout.md-gutter > .md-layout-item {
  padding-right: 0 !important;
}

::v-deep .v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, .7) !important;
}

::v-deep .md-menu-content {
  min-width: 80px !important;
  max-width: 87px !important;
}

.text-over-flow {
  // width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

// #genreData:hover{

// 	margin-top: 10px;
// 	overflow: visible;
// 	white-space: normal;

// }

.box-1 {
  flex: 5;
  display: flex;

  place-content: center
}

.box-2 {
  flex: 32;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
}

.box-3 {
  padding-top: 6px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 13;
}

.box-4 {
  flex: 15;
}

.box-5 {
  flex: 10;
}

.box-6 {
  text-align: -webkit-center;
  flex: 10;
}

.box-7 {
  flex: 5;
}

.box-8 {
  flex: 5;
}

.box-9 {
  flex: 5;
}

.box-10 {
  flex: 5;
}
.box-11 {
  flex: 10;
}
.row {
  align-items: flex-start;
  display: flex;
  height: 56px;
  padding: 12px 0 12px 10px;
}

.frame-433 {
  align-items: flex-start;
  display: flex;
  gap: 12px;
  margin-bottom: -3px;
}

.frame-431 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.artist-name {
  margin-top: -1px;
}

.mask-group {
  width: 25px;
  height: 25px;
}

.album-art {
  background-color: var(--material-themerefsecondarysecondary1007);
  border-radius: 2px;
  display: flex;
  height: 25px;
  min-width: 25px;
  overflow: hidden;
  justify-content: center;
  cursor: pointer;

}

.container {
  display: flex;
  align-items: center;
  background-color: var(--shark);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--onyx);
}

.more-songs {
  background: #2F2F2F !important;
}

.sec-container {
  display: flex;
  align-items: center;
  // background-color: var(--shark);
  border-radius: 5px;
  // border-color: var(--onyx);
  align-items: flex-start;
  height: 56px;
  padding: 16px;
  margin-bottom: 3px;
  background: #3A3A3A;
  text-align: left;
  @media only screen and (max-width: 500px) {
    font-size: small;
  };
}

.sec-box-1 {
  flex: 30;
}

.sec-box-2 {
  flex: 15;
}

.play-icon {
  cursor: pointer;

  // transform: translate(-100%, 0%);
  position: absolute;
  opacity: 1;
  font-size: 20px !important;
}

.flex-menu {
  align-items: center;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.explicit-content {
  font-family: var(--font-family-figtree);
  font-size: var(--font-size-xxs);
  font-weight: bolder;
  font-style: normal;
  border: 1.5px solid var(--nobel);
  color: white;
  margin-right: 2px;
}
.playing-icon {
  width: 60px;
  height: 60px;
  position: absolute;
  top: -4px;
  left: -4px;
}
.replaced-item {
  background-color: purple!important;
  // opacity: 0.2 !important;
}
</style>
