<template>
  <fullscreen v-model="fullscreen" :teleport="teleport" :page-only="pageOnly">
    <div>
      <div v-if="!fullscreen">
        <TrainerDashboardBluePrintFlexRate
            v-if="graphDataFromInterval.length"
            :graphDataProp="graphDataFromInterval"
            classProp="graph2"
        />

        <div class="player-timeline">
          <input
              v-model="percentComplete"
              @mousedown="startDragging"
              @mouseup="endDragging"
              :style="{ background: createBackgroundString }"
              id="duration-input"
              class="player-progress"
              type="range"
              min="0"
              max="100"
              step="0.1"
          />
        </div>
      </div>

    </div>
    <div v-show="isPlayerActive || showPlayer" class="player" :class="{
                'full-height': fullscreen}">

      <div
          v-if="isIntervalPlaying == false && !fullscreen"
          id="close-media-player"
          @click="closePlayer"
      >
        <md-icon id="close-icon  " class="md-theme-default "> close</md-icon>
      </div>

      <div style="display:flex;width:100%;height:100%"
           :style="{ background: this.playlistDurationInterval[0].color }" v-if="fullscreen">

        <div style="display:flex;flex-direction:column;flex: 60">

          <div v-if="showSections && !isDesktop" style="flex: 70;overflow-y: scroll;padding: 20px">
            <div class="back-button" @click="showSections = false">
              <md-icon style="color:white;font-size: 14px!important;">arrow_back_ios</md-icon>
            </div>
            <div style="margin-top: 50px">
              <div v-if="sections.length" v-for="(section, index) in sections" :key="section.id">
                <div class="container figtree-medium-white-18px">
                  <div class="box-1">
                    {{ section.title }}
                  </div>
                  <div class="box-2">
                    {{ convertHHMMSStoHMSeconds(section.duration) }}
                  </div>

                  <div class="box-1">
                    {{ section.type_id == 1 ? 'EMOM' : section.type_id == 2 ? 'Work/Rest' : 'Custom' }}
                  </div>

                </div>
                <div>

                  <TrainerDashboardBluePrintTrackPlaylist ref="BluePrintTrackPlaylist"
                                                          v-for="(interval, interval_index) in listSectionIntervals(section.id)"
                                                          :id="'interval_'  + interval_index"
                                                          :contentRating="playlistDurationInterval[intervalindex].has_explicit_song"
                                                          :trackData="interval"
                                                          :timeLineProp="interval.timeline"
                                                          :index-prop="interval.order"
                                                          :key="interval_index + 'track'"
                                                          :intervalWithPlayIconIdProp.sync="playlistDurationInterval[intervalindex].interval_song_id"
                                                          :fullscreen="true"
                  />
                </div>
              </div>
            </div>
          </div>

          <div v-if="showWorkoutSummary && !isDesktop"
               style="flex: 70;overflow-y: scroll;padding: 20px;align-content: center">
            <div class="back-button" @click="showWorkoutSummary = false">
              <md-icon style="color:white;font-size: 14px!important;">arrow_back_ios</md-icon>
            </div>
            <div style="margin-top: 50px">
              <div class="workout-box" v-if="sections.length" v-for="(section, index) in sections" :key="section.id">
                <div style="flex: 25;" class="figtree-medium-sonic-silver-16px">
                  {{ section.title }}
                </div>
                <div class="summary-box figtree-medium-sonic-silver-14px">
                  {{ section.description ? section.description : 'No summary' }}
                </div>
              </div>
            </div>
          </div>

          <div v-if="isDesktop || (!showWorkoutSummary && !showSections)" style="flex:20;padding: 20px">
            <div style="display: flex">
              <div style="flex: 5">
                <div @click="toggle" style="cursor: pointer">
                  <md-icon>keyboard_arrow_down</md-icon>
                </div>

              </div>
              <div style="flex: 90">
                <p class="figtree-medium-white-16px">{{ currentIntervalPlayedSongName }}</p>
                <p class="figtree-medium-white-12px">{{ currentIntervalPlayedArtistName }}</p>
                <p class="figtree-medium-white-14px">{{ songName }}</p>
              </div>
              <div style="flex: 5">
                <!--                <md-icon>more_vert</md-icon>-->
              </div>
            </div>
          </div>
          <div v-if="isDesktop || (!showWorkoutSummary && !showSections)" style="flex:50">
            <div class="counter-center">
              <div style="flex: 15" class="figtree-medium-white-20px">
                Interval {{ myIntervalIndex + 1 }}
              </div>
              <div class="full-screen-counter">
                {{ isCountUp ? intervalTimeUp : intervalTimeDown }}
              </div>

              <div style="flex: 15;display:flex;align-self: center;">
                <div class="button figtree-medium-star-dust-14px"
                     :class="{'button-active': !isCountUp}" @click="isCountUp = !isCountUp">
                  CountDown
                </div>
                <div class="button figtree-medium-star-dust-14px"
                     :class="{'button-active': isCountUp}" @click="isCountUp = !isCountUp">
                  CountUp
                </div>
              </div>
              <div  v-if="!isDesktop" style="flex: 5;align-self: center;margin-top: 20px;">
                <p class="figtree-medium-white-16px">{{ currentTime }} / {{ durationTime }}</p>
              </div>
            </div>
          </div>

          <div v-if="!isDesktop" style="flex:20">
            <div>
              <TrainerDashboardBluePrintFlexRate
                  v-if="graphDataFromInterval.length"
                  :graphDataProp="graphDataFromInterval"
                  :color="this.playlistDurationInterval[0].color"
                  classProp="graph2"
              />

              <div class="player-timeline" style="position: relative;top: -24px;">
                <input
                    v-model="percentComplete"
                    @mousedown="startDragging"
                    @mouseup="endDragging"
                    :style="{ background: createBackgroundString }"
                    id="duration-input-full-screen"
                    class="player-progress"
                    type="range"
                    min="0"
                    max="100"
                    step="0.1"
                />
              </div>
            </div>

            <div style="display:flex;flex: 20;justify-content: center"
                 :class="{'div-margin': fullscreen}">

              <div style="display: contents;flex: 50">
                <div>
                  <md-icon class="md-width-2x"></md-icon>
                </div>
                <div>
                  <md-icon  class="md-width-2x disabled-players" v-if="disableButtons">
                    skip_previous
                  </md-icon>
                  <md-icon v-else title="Previous Interval" @click.native="goToPreviousSongOfIntervalOfMix()"
                           class="md-width-2x">
                    skip_previous
                  </md-icon>
                </div>

                <div>
                  <a class="add-margin" :title="mixEnded ? 'Restart' : (this.isSpotifyPlaying) ? 'Pause' : 'Play'">
                    <md-icon :class="{'disabled-players': disableButtons}" class=" pause md-size-2x" v-if="mixEnded"
                             @click.native="restartPlayingMix">
                      play_circle_filled
                    </md-icon>
                    <md-icon :class="{'disabled-players': disableButtons}" class=" pause md-size-2x" v-else-if="playing"
                             @click.native="togglePlay">
                      pause_circle_filled
                    </md-icon>
                    <md-icon :class="{'disabled-players': disableButtons}" class="md-size-2x play" v-else
                             @click.native="togglePlay">
                      play_circle_filled
                    </md-icon>
                  </a>
                </div>

                <div>
                  <md-icon class="md-width-2x disabled-players" v-if="disableButtons"> skip_next</md-icon>
                  <md-icon v-else title="Next Interval" @click.native="nextSong" class="md-width-2x"> skip_next
                  </md-icon>
                </div>

                <div>
                  <md-icon :class="{'disabled-players': disableButtons}" :title="mixEnded ? 'Restart' : 'Start over'" @click.native="restart" class="md-width-2x">
                    restart_alt
                  </md-icon>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!isDesktop"
               style="display: flex;flex:10;text-align: center;align-items: center;border-radius: 10px 10px 0 0;filter: brightness(0.85);"
               :style="{ background: this.playlistDurationInterval[0].color }">
            <md-button class="workout" :class="{'tab-active': showSections}" style="flex: 50" @click="toggleSections">Up
              Next
            </md-button>
            <md-button class="workout" :class="{'tab-active': showWorkoutSummary}" style="flex: 50"
                       @click="toggleWorkoutSummary">Workout Summary
            </md-button>
          </div>

        </div>
        <div v-if="isDesktop" class="desktop-div">
          <md-tabs md-dynamic-height md-alignment="fixed" end>
            <md-tab md-label="Up Next">
              <div v-if="sections.length" v-for="(section, index) in sections" :key="section.id">
                <div class="container figtree-medium-white-18px">
                  <div class="box-1">
                    {{ section.title }}
                  </div>
                  <div class="box-2">
                    {{ convertHHMMSStoHMSeconds(section.duration) }}
                  </div>

                  <div class="box-1">
                    {{ section.type_id == 1 ? 'EMOM' : section.type_id == 2 ? 'Work/Rest' : 'Custom' }}
                  </div>

                </div>
                <div>

                  <TrainerDashboardBluePrintTrackPlaylist ref="BluePrintTrackPlaylist"
                                                          v-for="(interval, interval_index) in listSectionIntervals(section.id)"
                                                          :id="'interval_'  + interval_index"
                                                          :contentRating="playlistDurationInterval[intervalindex].has_explicit_song"
                                                          :trackData="interval"
                                                          :timeLineProp="interval.timeline"
                                                          :index-prop="interval.order"
                                                          :key="interval_index + 'track'"
                                                          :intervalWithPlayIconIdProp.sync="playlistDurationInterval[intervalindex].interval_song_id"
                                                          :fullscreen="true"
                  />
                </div>
              </div>
            </md-tab>

            <md-tab md-label="Workout">
              <div class="workout-box" v-if="sections.length" v-for="(section, index) in sections" :key="section.id">
                <div style="flex: 25;" class="figtree-medium-sonic-silver-16px">
                  {{ section.title }}
                </div>
                <div class="summary-box figtree-medium-sonic-silver-14px">
                  {{ section.description ? section.description : 'No summary' }}
                </div>
              </div>
            </md-tab>
          </md-tabs>
        </div>
        <div v-if="isDesktop && fullscreen" class="desktop-fullscreen">

          <div style="width: 100%">
            <TrainerDashboardBluePrintFlexRate
                v-if="graphDataFromInterval.length"
                :graphDataProp="graphDataFromInterval"
                color="#393838"
                classProp="graph2"
            />

            <div class="player-timeline" style="position: relative;top: -24px;">
              <input
                  v-model="percentComplete"
                  @mousedown="startDragging"
                  @mouseup="endDragging"
                  :style="{ background: createBackgroundString }"
                  id="duration-input-full-screen2"
                  class="player-progress"
                  type="range"
                  min="0"
                  max="100"
                  step="0.1"
              />
            </div>
          </div>

          <div class="player-controls" style="width: 100%">

            <div style="display:flex;flex: 40;align-items: center">

              <div class="frame-433"
                   style="flex: 60;align-items: center;text-align: start;padding-left: 10px;overflow: hidden">
                <div class="album-art">
                  <img v-if="currentIntervalPlayedArtistName" class="mask-group" :src="artWorkUrl" alt="Mask group"/>
                </div>
                <div class="frame-431">
                  <div class="figtree-medium-white-12px">{{ currentIntervalPlayedSongName }}</div>
                  <div class="figtree-normal-white-10px">{{ currentIntervalPlayedArtistName }}</div>
                </div>
              </div>

              <div class="media-player-duration">
                <p class="figtree-normal-white-10px" style="color: #B2B2B2">{{ currentTime }} / {{ durationTime }}</p>
              </div>

            </div>

            <div style="display:flex;flex: 20 ;   justify-content: center;align-items: center">
              <div style="display: contents;flex: 50">
                <div>
                  <md-icon class="md-width-2x disabled-players"
                           v-if="disableButtons">
                    skip_previous
                  </md-icon>
                  <md-icon v-else title="Previous Interval" @click.native="goToPreviousSongOfIntervalOfMix()"
                           class="md-width-2x">
                    skip_previous
                  </md-icon>
                </div>

                <div>
                  <a class="add-margin" :title="mixEnded ? 'Restart' : (this.isSpotifyPlaying) ? 'Pause' : 'Play'">

                    <md-icon :class="{'disabled-players': disableButtons}" class=" pause md-size-2x" v-if="mixEnded"
                             @click.native="restartPlayingMix">
                      play_circle_filled
                    </md-icon>
                    <md-icon :class="{'disabled-players': disableButtons}" class=" pause md-size-2x" v-else-if="playing"
                             @click.native="togglePlay">
                      pause_circle_filled
                    </md-icon>
                    <md-icon :class="{'disabled-players': disableButtons}" class="md-size-2x play" v-else
                             @click.native="togglePlay">play_circle_filled
                    </md-icon>
                  </a>
                </div>

                <div>
                  <md-icon class="md-width-2x disabled-players" v-if="disableButtons"> skip_next</md-icon>
                  <md-icon v-else title="Next Interval" @click.native="nextSong" class="md-width-2x"> skip_next
                  </md-icon>
                </div>
              </div>

            </div>

            <div style="display: flex;flex: 40;justify-content: end;align-items: center">
              <div>
                <md-icon :class="{'disabled-players': disableButtons}" :title="mixEnded ? 'Restart' : 'Start over'" @click.native="restart" class="md-width-2x">
                  restart_alt
                </md-icon>
              </div>

              <div class="media-player-volume">
                <div>
                  <a @click.prevent="mute">
                    <md-icon v-if="!muted"> volume_up</md-icon>
                    <md-icon v-else> volume_off</md-icon>
                  </a>
                </div>
              </div>

              <div class="media-player-volume">
                <div class="volume-bar">
                  <a :title="volumeTitle" :alt="volumeTitle">
                    <input
                        v-model="volume"
                        :style="{ background: createBackgroundVolumeString }"
                        v-show="showVolume"
                        class="player-volume"
                        type="range"
                        min="0"
                        max="100"
                    />
                  </a>
                </div>
              </div>

              <div v-if="isIntervalPlaying == false" class="fullscreen-icon" @click="toggle">
                <img class="union" src="@/assets/images/exitFullscreen.png" alt="fullscreen"/>
              </div>

            </div>

          </div>
        </div>

      </div>

      <div class="player-controls">

        <div v-if="!fullscreen" style="display:flex;flex: 40;align-items: center">

          <div class="frame-433"
               style="flex: 60;align-items: center;text-align: start;padding-left: 10px;overflow: hidden">
            <div class="album-art">
              <img v-if="currentIntervalPlayedArtistName" class="mask-group" :src="artWorkUrl" alt="Mask group"/>
            </div>
            <div class="frame-431">
              <div class="figtree-medium-white-12px">{{ currentIntervalPlayedSongName }}</div>
              <div class="figtree-normal-white-10px">{{ currentIntervalPlayedArtistName }}</div>
            </div>
          </div>

          <div class="media-player-duration">
            <p class="figtree-normal-white-10px" style="color: #B2B2B2">{{ currentTime }} / {{ durationTime }}</p>
          </div>

        </div>

        <div v-if="!fullscreen" style="display:flex;flex: 20 ;   justify-content: center"
             :class="{'div-margin': fullscreen}">


          <div style="display: contents;flex: 50">

            <div>
              <md-icon class="md-width-2x disabled-players" v-if="disableButtons">
                skip_previous
              </md-icon>
              <md-icon v-else title="Previous Interval" @click.native="goToPreviousSongOfIntervalOfMix()"
                       class="md-width-2x">
                skip_previous
              </md-icon>
            </div>

            <div>
              <a class="add-margin" :title="mixEnded ? 'Restart' : (this.isSpotifyPlaying) ? 'Pause' : 'Play'">

                <md-icon :class="{'disabled-players': disableButtons}" class=" pause md-size-3x" v-if="mixEnded"
                         @click.native="restartPlayingMix">
                  play_circle_filled
                </md-icon>
                <md-icon :class="{'disabled-players': disableButtons}" class=" pause md-size-2x" v-else-if="playing"
                         @click.native="togglePlay">
                  pause_circle_filled
                </md-icon>
                <md-icon :class="{'disabled-players': disableButtons}" class="md-size-2x play" v-else
                         @click.native="togglePlay">play_circle_filled
                </md-icon>
              </a>
            </div>

            <div>
              <md-icon class="md-width-2x disabled-players" v-if="disableButtons"> skip_next</md-icon>
              <md-icon v-else title="Next Interval" @click.native="nextSong" class="md-width-2x"> skip_next
              </md-icon>
            </div>
          </div>

        </div>

        <div v-if="!fullscreen" style="display: flex;flex: 40;justify-content: end;align-items: center">
          <div>
            <md-icon :class="{'disabled-players': disableButtons}" :title="mixEnded ? 'Restart' : 'Start over'" @click.native="restart" class="md-width-2x">
              restart_alt
            </md-icon>
          </div>

          <div class="media-player-volume">
            <div id="">
              <a @click.prevent="mute">
                <md-icon v-if="!muted"> volume_up</md-icon>
                <md-icon v-else> volume_off</md-icon>
              </a>
            </div>
          </div>

          <div class="media-player-volume">
            <div class="volume-bar">
              <a :title="volumeTitle" :alt="volumeTitle">
                <input
                    v-model="volume"
                    :style="{ background: createBackgroundVolumeString }"
                    v-show="showVolume"
                    class="player-volume"
                    type="range"
                    min="0"
                    max="100"
                />
              </a>
            </div>
          </div>


          <div v-if="isIntervalPlaying == false" class="fullscreen-icon" @click="toggle">
            <img style="width: 14px;height: 14px;" src="@/assets/images/goFullscreen.png" alt="fullscreen"/>
          </div>
        </div>

      </div>
      <!-- <audio :loop="innerLoop" ref="audiofile" :src="songFile" ></audio> -->
      <audio ref="audiofile" preload="auto" :src="countdown_beep_url" ></audio>
      <audio ref="audiofile" preload="auto" :src="riser_down_lifter_url"></audio>
      <audio ref="audiofile" preload="auto" :src="workout_end_indicator_url" ></audio>
      <audio ref="audiofile" preload="auto" :src="downLifter_url"></audio>
      <audio ref="audiofile" preload="auto" :src="DJ_scratch_url"></audio>
      <AudioPlayerPlaylist
          :show-playerlist-prop.sync="showPlayerlist"
          :intervals-prop="intervals"
          :durationSecondsProp="durationSeconds"
          :percentCompleteProp.sync="percentComplete"
          :key="dialogKey + '_playlist'"
      />
    </div>
    <FullScreenActivationDialog 
      :showDialog.sync="showFullScreenDialog"
      :FullscreenExited.sync="FullscreenExited"
      :key="FullScreenDialogKey + '_playlist'"
      @activateFullScreen="toggle"
    />
  </fullscreen>

</template>

<script>

import AudioPlayerPlaylist from "@/components/AudioPlayer/AudioPlayerPlaylist";
import PlaylistService from "@/services/playlists.service";
import TrainerDashboardBluePrintFlexRate from '@/components/TrainerDashboard/TrainerDashboardBluePrintFlexRate';
import {mapGetters} from "vuex";
import {component} from 'vue-fullscreen'
import NoSleep from 'nosleep.js';
import draggable from 'vuedraggable'
import TrainerDashboardBluePrintTrackPlaylist
  from "@/components/TrainerDashboard/TrainerDashboardBluePrintTrackPlaylist.vue";
import $axios from "../../plugins/axios";
import {CheckWindowWidthMixin} from '../../mixins/CheckWindowWidthMixin';
import {MusicKitHelperMixin} from "../../mixins/MusicKitHelperMixin";
import debounce from 'lodash.debounce'
import FullScreenActivationDialog from "./FullScreenActivationDialog.vue";

let intervalflag;
export default {
  mixins: [CheckWindowWidthMixin, MusicKitHelperMixin],
  components: {
    component,
    AudioPlayerPlaylist,
    TrainerDashboardBluePrintFlexRate,
    draggable,
    TrainerDashboardBluePrintTrackPlaylist,
    FullScreenActivationDialog
},
  props: {
    file: {
      type: String,
      default: null
    },

    loop: {
      type: Boolean,
      default: false
    },

    showPlayer: {
      type: Boolean,
      default: false
    },

    currentSecondsForTemplateProp: {
      type: Number,
      default: null
    },

    durationSecondsForTemplateProp: {
      type: Number,
      default: null
    }
  },

  data: () => ({
    artWorkUrl: null,
    audio: undefined,
    riser_downLifter: undefined,
    currentSeconds: 0,
    durationSeconds: 0,
    innerLoop: false,
    loaded: false,
    playing: false,
    previousVolume: 35,
    showVolume: true,
    volume: 100,
    isPlayerActive: false,
    showPlayerlist: false,
    intervals: null,
    dialogKey: 0,
    currenPlayedInterval: null,
    nextIntervalNote: '',
    countdown_beep_url: '',
    riser_down_lifter_url:'',
    downLifter_url:'',
    DJ_scratch_url:'',
    workout_end_indicator_url: '',
    countbeep_audio: undefined,
    workout_end_indicator_audio: undefined,
    down_lifter: undefined,
    DJ_scratch: undefined,
    music: null,
    intervalindex: 0,
    prevSongDuration: 0,
    isPaused: false,
    total_duration: 0,
    interval_id: 0,
    nextinterval_id: 0,
    musicKitVolume: 0,
    assetsVolume: 0.85,
    timer: 0,
    counter: 0,
    diff: 0,
    mixEnded: false,
    disableButtons: false,
    skipPrevSecLimit: 2,
    audios: [],
    volumeIncrementDecrStep: 1 / 3,
    fullscreen: false,
    teleport: false,
    pageOnly: false,
    flexList: [],
    intervalDurations: [],
    myIntervalIndex: 0,
    isCountUp: false,
    noSleep: null,
    sections: [],
    showSections: false,
    showWorkoutSummary: false,
    showFullScreenDialog:false,
    FullScreenDialogKey:0,
    transitionalEffectsOn:null,
    FullscreenExited:false,
    Sptifyplayer: null,
    isDragging: false,
    // isSpotifyPlaying:false
  }),

  filters: {

    getNameForCurrentPlayingInterval(currentInterval) {

      if (currentInterval.song.title) {

        return currentInterval.song.title;
      } else {

        return "";
      }
    },

    getArtistNameForCurrentPlayingInterval(currentInterval) {

      if (currentInterval.song.artist.name) {

        return currentInterval.song.artist.name;
      } else {

        return "";
      }
    },
  },

  computed: {

    ...mapGetters(
        {
          artistName: 'audioPlayer/getArtist',
          songName: 'audioPlayer/getSongName',
          songFile: 'audioPlayer/getSong',
          startSeconds: 'audioPlayer/getStartSeconds',
          endSeconds: 'audioPlayer/getEndSeconds',
          playlistId: 'audioPlayer/getPlaylistId',
          playlistDurationInterval: 'audioPlayer/getPlaylistDurationInterval',
          autoPlay: 'audioPlayer/getAutoplay',
          isIntervalPlaying: 'audioPlayer/getIsIntervalPlaying',
          intervalsWithTimeline: 'audioPlayer/getIntervals',
          musicIntervals: 'audioPlayer/getAppleIntervals',
          songsForMix: 'audioPlayer/getSongsForMix',
          isSpotifyPlaying :'isSpotifyPlaying',
          currentUser: 'auth/getCurrentUser',
        },
    ),

    graphDataFromInterval() {

      let graphData = [];

      for (const interval of this.intervalsWithTimeline) {

        let newIntervalFirstPoint = {};
        let newIntervalSecondPoint = {};

        //set first point
        let timeObj = interval.timeline.split(':');
        let secondsDuration = (+timeObj[0]) * 60 * 60 + (+timeObj[1]) * 60 + (+timeObj[2]);

        newIntervalFirstPoint.time = secondsDuration * 1000;
        newIntervalFirstPoint.bits = interval.energy;

        graphData.push(newIntervalFirstPoint);

        //set second point
        timeObj = interval.duration.split(':');
        let endOfIntervalPoint = (+timeObj[0]) * 60 * 60 + (+timeObj[1]) * 60 + (+timeObj[2]);
        endOfIntervalPoint = endOfIntervalPoint + secondsDuration;

        newIntervalSecondPoint.time = endOfIntervalPoint * 1000;
        newIntervalSecondPoint.bits = interval.energy;

        graphData.push(newIntervalSecondPoint);
      }

      return graphData;
    },

    apple() {
      return this.$store.dispatch('audioPlayer/getAppleIntervals');
    },
    currentTime() {

      let result = this.convertTimeHHMMSS(this.timer);

      return (result) ? result : "00:00";

    },
    intervalTimeDown() {

      return this.intervalDurations[this.myIntervalIndex] - this.counter > 0 ?
          this.convertTimeHHMMSS((this.intervalDurations[this.myIntervalIndex] - this.counter))
          : "00:00";

    },

    intervalTimeUp() {

      return this.counter <= this.intervalDurations[this.myIntervalIndex] ?
          this.convertTimeHHMMSS(this.counter)
          : "00:00";

    },

    durationTime() {

      return this.convertTimeHHMMSS(this.durationSeconds);
    },

    muted() {

      return this.volume / 100 === 0;
    },

    volumeTitle() {

      return `Volume (${this.volume}%)`;
    },

    progressStyle() {

      return {width: this.percentComplete + '%'}
    },

    createBackgroundString() {
      // rgba(47, 42, 64, 0.8)

      return `linear-gradient(to right, rgb(47 42 64 / 80%)  0%, rgb(47 42 64 / 80%) ${this.percentComplete}%, transparent ${this.percentComplete}%, transparent 100%)`;
    },
    
    createBackgroundVolumeString() {
  
      return `linear-gradient(to right, #B2B2B2  0%, #B2B2B2 ${this.volume}%, #777777 ${this.volume}%, #777777 100%)`;
    },
    percentComplete: {

      get: function () {

        if (this.durationSeconds == 0 || this.currentSeconds == 0) {
          return 0;
        }

        return Number(this.currentSeconds / this.durationSeconds * 100);
      },
      set:  async function  (newValue)  {
        this.disableButtons = true;
        if (this.playing) {
          await this.$store.dispatch('pause');
          clearTimeout(intervalflag);
          this.isPaused = true;
          this.playing = false;
        }
        const localCurrentSeconds = parseInt((this.durationSeconds * newValue) / 100);
        // this.audio.currentTime = localCurrentSeconds;
        this.currentSeconds = localCurrentSeconds;
        this.timer = localCurrentSeconds;
        
        return this.solve(newValue,localCurrentSeconds);
      }
    },

    stylePercentComplete() {

      return {background: `linear-gradient(to right, #82CFD0 0%, #82CFD0 ' + ${this.percentComplete} + '%, #fff ' + ${this.percentComplete} + '%, white 100%)`};
    },

    intervalsEndsInPercentageInTheMix() {

      return this.intervalsWithTimeline ? this.getIntervalsEndingTimeInPercentage(this.intervalsWithTimeline) : [];
    },

    mainIntervalsEndsInPercentage() {

      return this.intervalsWithTimeline ? this.getIntervalsEndingForNotes(this.intervalsEndsInPercentageInTheMix) : [];
    },

    currentIntervalPlayed() {

      let currentTimeline = -1;

      for (const argumentsKey in this.intervalsWithTimeline) {

        if (this.intervalsWithTimeline[argumentsKey].interval_songs.length > 1) {

          currentTimeline = this.convertHHMMSStoSeconds(this.intervalsWithTimeline[argumentsKey].timeline);

          for (const songKey in this.intervalsWithTimeline[argumentsKey].interval_songs) {

            let intervalNote = {name: this.intervalsWithTimeline[argumentsKey].name}
            let interval = {...intervalNote, ...this.intervalsWithTimeline[argumentsKey].interval_songs[songKey]};

            currentTimeline += interval.song_duration;

            if (this.currentSeconds < currentTimeline) {

              return interval;
            }
          }

        } else {

          let intervalTimelineSecond = this.convertHHMMSStoSeconds(this.intervalsWithTimeline[argumentsKey].timeline);
          let durationSecond = this.convertHHMMSStoSeconds(this.intervalsWithTimeline[argumentsKey].duration);

          let currentTimeline = intervalTimelineSecond + durationSecond;

          if (this.currentSeconds < currentTimeline) {

            let intervalNote = {name: this.intervalsWithTimeline[argumentsKey].name}
            let interval = {...intervalNote, ...this.intervalsWithTimeline[argumentsKey].interval_songs[0]};

            return interval;
          }

        }
      }

      if (this.currentSeconds >= currentTimeline) {

        return null;
      }
    },

    currentIntervalPlayedSongName() {

      if (this.currentIntervalPlayed) {

        return this.currentIntervalPlayed.song.title;
      }

      return "";
    },


    currentIntervalPlayedArtistName() {
      this.currentIntervalPlayedArtWork()
      if (this.currentIntervalPlayed) {

        return this.currentIntervalPlayed.song.artist.name;
      }

      return "";
    },

    currentIntervalNote() {

      if (this.currentIntervalPlayed) {

        return this.currentIntervalPlayed.name;
      }

      return "";
    },
  },

  watch: {
    startSeconds: {

      handler: function (newVal, oldVal) {

        // this.audio.currentTime = newVal;
      }
    },

    endSeconds: {

      handler: function (newVal, oldVal) {
        this.durationSeconds = newVal;
        this.music.stop()
        this.intervalindex = 0;
        this.myIntervalIndex = 0;
        this.currentSeconds = 0;
        this.prevSongDuration = 0;


      }
    },
    currentSeconds: {
      handler: async function (newVal, oldVal) {
        // //console.log(newVal, this.total_duration, this.timer )
        if (newVal == 0 && this.playing) {
          this.DJ_scratch.play();
        }
        if (newVal+1  >= this.total_duration ||  (this.timer  >= this.total_duration )){
          this.currentSeconds = this.timer;
          this.stop();
          clearTimeout(intervalflag);
          if (this.workout_end_indicator_url != null) {
            this.workout_end_indicator_audio.volume = (this.volume / 100 >= this.assetsVolume) ? this.assetsVolume : this.volume / 100;
            this.workout_end_indicator_audio.play();
          }
          await this.$store.dispatch('pause');
          this.timer = this.durationSeconds;
          this.counter = this.intervalDurations[this.myIntervalIndex];
          this.currentSeconds = this.durationSeconds;
          this.mixEnded = true;
          // //console.log("this.mixEnded ",this.mixEnded)
        }
        else this.mixEnded = false;
      }
    }
    ,

    durationSecondsForTemplateProp(value) {

      // this.durationSeconds = value;
    },


    currentIntervalPlayed(newValue, oldValue) {

      if (newValue == null) {

        return;
      }

      if (oldValue == null || newValue.id != oldValue.id) {

        this.getNextIntervalNote();
      }
    },

    async playing(newVal, oldVal) {
      // await this.isSpotifyPlaying;
      ////console.log("this.isSpotifyPlaying", this.isSpotifyPlaying);
      if (!this.isDragging){
        // console.log("watch playing",newVal)
        this.disableButtons = true;

        if (!this.isSpotifyPlaying) {
          ////console.log("startplay")
          this.StartPlayingMix()
          this.playing = true
  
        } else {
          // console.log("watchin playing inside else ")
          clearTimeout(intervalflag);
          await this.$store.dispatch('pause');
          this.playing = false
          this.isPaused = true;
          this.pauseAudioDuringtransition()
        }
        setTimeout(() => {
          this.disableButtons = false;
  
        }, 800);
      }
    },

    async volume(value) {
      await this.$store.dispatch('setVolume', (this.playlistDurationInterval[this.intervalindex].volume / 100) * (this.volume / 100));
      this.setAudiosVolume()

    },

  },

  methods: {
    async getTransactionalEffectSettings(){

				await $axios.get('/settings')
                    .then((res) => {
						          const settingsData = res.data;
          						this.transitionalEffectsOn = settingsData.filter(sett => sett.key =="transitional_effects_on")[0].value;
						          this.transitionalEffectsOn = this.transitionalEffectsOn == 1 ? true : false;
						          this.assetsVolume = (settingsData.filter(sett => sett.key =="transitional_effects_volume")[0].value)/100;
                    })
    },
    startDragging() {
      this.isDragging = true;
    },
    async endDragging() {

      if (this.isDragging) {
        this.isDragging = false;      
      }
    },
    async playAfterSeek(trackUri) {
      await this.$store.dispatch('playSongAndPause', trackUri);
      await this.$store.dispatch('seek', this.playlistDurationInterval[this.intervalindex].song_start_sec + this.counter);
      await this.$store.dispatch('pause');

      await this.$store.dispatch('setVolume', (this.playlistDurationInterval[this.intervalindex].volume / 100) * (this.volume / 100));
      await this.$store.dispatch('pause');
      this.playing = false;

  },
    solve: debounce(async function (newValue,localCurrentSeconds) {
      
      let sumOfDuration1 = 0;
      let index = 0;
      for (let i = 0; i < this.intervalDurations.length; i++) {
        if (sumOfDuration1 + this.intervalDurations[i] >= localCurrentSeconds) {
          index = i;
          break;
        }
        sumOfDuration1 += this.intervalDurations[i];
      }

      let sumOfDuration = 0;

      for (let i = 0; i < this.playlistDurationInterval.length; i++) {
        if (sumOfDuration + this.playlistDurationInterval[i].song_duration >= localCurrentSeconds) {
          const extraSeconds = localCurrentSeconds - sumOfDuration
          this.intervalindex = i;
          this.myIntervalIndex = index;
          this.interval_id = this.playlistDurationInterval[this.intervalindex].interval_id;
          this.nextinterval_id = (this.playlistDurationInterval[this.intervalindex + 1] == undefined) ? this.nextinterval_id : this.playlistDurationInterval[this.intervalindex + 1].interval_id;
          this.timer = sumOfDuration + extraSeconds
          this.currentSeconds = sumOfDuration + extraSeconds
          this.prevSongDuration = sumOfDuration

          this.counter = localCurrentSeconds - sumOfDuration1
          const trackUri = this.songsForMix[this.intervalindex];

          if (!this.isDragging) {
            await this.playAfterSeek(trackUri);
          }

          break;
        }
        sumOfDuration += this.playlistDurationInterval[i].song_duration;
      }

      await this.$store.dispatch('audioPlayer/updatePlayingIntervalId', this.playlistDurationInterval[this.intervalindex].interval_song_id);

      this.disableButtons = false;
      return newValue;
    }, 500),

    toggleWorkoutSummary() {
      this.showWorkoutSummary = !this.showWorkoutSummary;
      this.showSections = false;
    },
    toggleSections() {
      this.showSections = !this.showSections;
      this.showWorkoutSummary = false;
    },
    async currentIntervalPlayedArtWork() {
      if (!this.isDragging) {
        this.artWorkUrl =  this.currentUser.current_music_streaming_service == 1 ? 
                                      await this.getArtWork(this.playlistDurationInterval[this.intervalindex]?.streaming_id, 300) 
                                      : await this.$store.dispatch('fetchSpotifyArtWork',this.playlistDurationInterval[this.intervalindex]?.streaming_id,300);
      }
      
     
    },
    async getSections() {
      await $axios.get(`/templates/${this.playlistDurationInterval[0].template_id}`)
          .then((res) => {
            this.sections = res.data.sections;
          })
          .catch((error) => {
            let errMsg = error.response.data;
            this.$store.dispatch('showSnackbarMessage',
                {message: errMsg, duration: 6000, mode: 'fail'})
          });
    },

    convertHHMMSStoHMSeconds(timeHHMMSS) {
      const timeObj = timeHHMMSS.split(':');
      let res = (Number(timeObj[0]) !== 0 ? Number(timeObj[0]) + ' hrs ' : '') + (Number(timeObj[1]) !== 0 ? Number(timeObj[1]) + ' mins ' : '') + (Number(timeObj[2]) !== 0 ? Number(timeObj[2]) + ' Secs ' : '');

      return res;
    },

    listSectionIntervals(sectionId) {

      let intervalsWithTimeline = this.intervalsWithTimeline;

      intervalsWithTimeline = intervalsWithTimeline.filter((interval) => interval.section_id == sectionId)

      return intervalsWithTimeline;
    },

    getFlexType(interval) {
      switch (interval) {
        case 1 :
          return 'Stretch';
        case 2 :
          return 'Warm Up';
        case 3 :
          return 'Strength Training';
        case 4 :
          return 'Cardio';
        case 5 :
          return 'Sprint';
        default:
          return '';
      }
    },
    toggle() {
      this.fullscreen = !this.fullscreen;
      if (this.fullscreen) {
        if(!this.isDesktop) this.playing = false;
        if (!this.noSleep.isEnabled) this.noSleep.enable();
      } else {
        if (this.noSleep.isEnabled) this.noSleep.disable();
      }
    },
    pauseAudioDuringtransition() {
      this.audios.map((el => {
        if (el.currentTime != 0) el.pause()
      }))
    },
    setAudiosVolume() {
      const masterVolume = (this.volume / 100 >= this.assetsVolume) ? 100 >= this.assetsVolume : this.volume / 100;
      this.riser_downLifter.volume = masterVolume;
      this.countbeep_audio.volume = masterVolume;
      this.workout_end_indicator_audio.volume = masterVolume;
      this.down_lifter.volume = masterVolume;
    },

    resumeAudioEl() {
      this.audios.map((el => {
        if (el.currentTime) el.play();
      }))
    },
    convertTimeHHMMSS(val) {

      let hhmmss = new Date(val * 1000).toISOString().substr(11, 8);

      return hhmmss.indexOf("00:") === 0 ? hhmmss.substr(3) : hhmmss;
    },

    convertHHMMSStoSeconds(timeHHMMSS) {

      let timeObj = timeHHMMSS.split(':');
      let secondsDuration = (+timeObj[0]) * 60 * 60 + (+timeObj[1]) * 60 + (+timeObj[2]);

      return secondsDuration;
    },

    getPlaylistIntervals(id) {

      return PlaylistService.getIntervalsByPlaylist(id)
          .then((res) => {

            this.intervals = res;
          })
          .catch(() => {

            this.$store.dispatch('showSnackbarMessage', {
              message: 'Failed. Please, try again.',
              duration: 8000,
              mode: 'fail'
            });
          });
    },

    download() {

      this.stop();
      window.open(this.file, 'download');
    },

    // load() {

    //   if (this.audio.readyState >= 2) {

    //     this.loaded = true;

    //     if (!this.endSeconds) {

    //       this.durationSeconds = Number(this.audio.duration)
    //     }

    //   }

    // },

    mute() {
      if (this.muted) {
        return this.volume = this.previousVolume;
      }

      this.previousVolume = this.volume;
      this.volume = 0;
    },


    stop() {

      this.playing = false;
    },

    update(e) {

      if (this.currentSeconds >= this.durationSeconds) {
        this.stop();
      }
    },
// document
    /**
     *  restart method :
     * set clock timer to zero , Mute the player if it called during pause status,stop musicKit and changeToMediaAtIndex at 0 then seek to time and reset variables.
     */
    async restart() {
      if (!this.disableButtons) {
        if (this.mixEnded) {
          this.restartPlayingMix();
        } else {
          const trackUri = this.songsForMix[0];
          ////console.log("else",this.isSpotifyPlaying)
          if (!this.isSpotifyPlaying) {
            // await this.music.mute();
            await this.$store.dispatch('playSongAndPause', trackUri);
            await this.$store.dispatch('setVolume', 0);
          }
          else{
            await this.$store.dispatch('playSong', trackUri);  
            this.playing = true;
          }
          await this.$store.dispatch('seek',this.playlistDurationInterval[0].song_start_sec);
          this.resetVariables();
        }
      }
    },
    async restartPlayingMix() {
      if (!this.disableButtons) {
        const trackUri = this.songsForMix[0];
        this.resetVariables();
        this.mixEnded = false;
        await this.$store.dispatch('playSongAndPause', trackUri);   
        this.isPaused = false;   
        this.playing = true;
      }
    },
    togglePlay() {
      if (!this.isDesktop && !this.fullscreen && !this.FullscreenExited && !this.isSpotifyPlaying && this.timer === 0) {
        this.showFullScreenDialog =true;
        this.FullScreenDialogKey++;
      }
      else if (!this.disableButtons)
        this.playing = !this.playing;
    },
    resetVariables() {
      this.timer = 0;
      this.counter = 0;
      this.intervalindex = 0;
      this.myIntervalIndex = 0;
      this.currentSeconds = 0;
      this.prevSongDuration = 0;
    },
    StartPlayingMix() {
      if (intervalflag) {
        clearTimeout(intervalflag);
      }
      let currentSongVolume;
      this.countdown_beep_url = this.$device.ios ? this.playlistDurationInterval[this.intervalindex].countdown_beep_IOS_url : this.playlistDurationInterval[this.intervalindex].countdown_beep_url
      this.workout_end_indicator_url = this.playlistDurationInterval[this.intervalindex].workout_indicator_url
      this.riser_down_lifter_url = 'https://dev-reflex-radio-assets.s3.amazonaws.com/assets/10s-riser-and-downlifter.mp3';
      // this.riser_down_lifter_url = 'https://dev-reflex-radio-assets.s3.amazonaws.com/assets/10s-riser-and-downlifter-withcount-down.mp3';
      this.downLifter_url ='https://s3.amazonaws.com/dev-reflex-radio-assets/assets/Downlifter+3+sec.wav';
      currentSongVolume = this.playlistDurationInterval[this.intervalindex].volume;
      // ////console.log("isplaying:",this.isSpotifyPlaying);
      // ////console.log("this.currentSeconds:", this.currentSeconds);

      if (this.currentSeconds === 0 && !this.isSpotifyPlaying) {
        ////console.log("time ouuuuuuuuuuut startPlay")
        this.startPlay(this.playlistDurationInterval[this.intervalindex].song_start_sec, currentSongVolume)
      }
       // Recursive function to maintain a reliable 1-second interval
    const playbackLoop = async () => {
        if (this.playlistDurationInterval.length > this.intervalindex) {
            let song_start_sec = this.playlistDurationInterval[this.intervalindex].song_start_sec;
            let SongendSeconds = this.playlistDurationInterval[this.intervalindex].song_end_sec;
            this.interval_id = this.playlistDurationInterval[this.intervalindex].interval_id;
            this.nextinterval_id = this.playlistDurationInterval[this.intervalindex + 1]?.interval_id || this.nextinterval_id;

            // Fetch current playback time from Spotify
            let SpotifycurrentPlaybacktime = await this.$store.dispatch('currentPlaybackTime');
            currentSongVolume = this.playlistDurationInterval[this.intervalindex].volume;
            // ////console.log("SpotifycurrentPlaybacktime",SpotifycurrentPlaybacktime)
            // Calculate playback time adjustments
            this.currentSeconds = SpotifycurrentPlaybacktime
                ? (this.prevSongDuration + SpotifycurrentPlaybacktime) - song_start_sec
                : this.currentSeconds;
            this.timer++;
            this.counter++;
            this.diff = this.timer - this.currentSeconds;
            let remainingSeconds = Math.max(0, parseInt(SongendSeconds - SpotifycurrentPlaybacktime - this.diff));
            ////console.log("this.isPaused",this.isPaused);
            if (this.isPaused && !this.isSpotifyPlaying) {
                this.resumeAudioEl();
                ////console.log("time ouuuuuuuuuuut resume")
                await this.$store.dispatch('resume'); // Update to your specific play action
                this.isPaused = false;
            }

            if (this.playlistDurationInterval.length - 1 === this.intervalindex && this.mixEnded) return;
          // if (this.playlistDurationInterval.length - 1 === this.intervalindex) {
          //   // clearTimeout(intervalflag);
          //   ////console.log("this.mixEnded looop",this.mixEnded)
          //   // this.mixEnded = true;

          //   return;
          // }
          //   // Execute transition work
            this.transitionWork(remainingSeconds, currentSongVolume);
        }

        // Recursive call with a 1-second delay
        intervalflag = setTimeout(playbackLoop, 1000);
    };

    // Start the loop
    // if (this.playlistDurationInterval.length - 1 === this.intervalindex) return;
    playbackLoop();
      
    },

    transitionWork(remainingSeconds, currentSongVolume) {

      switch (remainingSeconds) {
        case 4:
          this.riser_downLifter.volume = (this.volume / 100 >= this.assetsVolume) ? this.assetsVolume : this.volume / 100;
          if(this.transitionalEffectsOn && (!this.$device.ios || this.countdown_beep_url == null ||(this.$device.ios && this.nextinterval_id == this.interval_id))) this.riser_downLifter.play();
          break;
        case 3:
          this.volumeDecreament(currentSongVolume);
          if (this.nextinterval_id != this.interval_id) {
            if (this.countdown_beep_url != null) {
              this.countbeep_audio.volume = (this.volume / 100 >= this.assetsVolume) ? this.assetsVolume : this.volume / 100;
              this.countbeep_audio.play(currentSongVolume);
            }
          }
          break;
        case 2 :
            this.volumeDecreament(currentSongVolume);
            
          break;
        case 1:
          this.volumeDecreament(currentSongVolume);
          break;
        case 0:
          this.prevSongDuration += this.playlistDurationInterval[this.intervalindex].song_duration;
          this.intervalindex += 1;
          if (this.nextinterval_id != this.interval_id) {
            this.myIntervalIndex += 1;
            this.counter = 0;
          }
          if (this.playlistDurationInterval.length > this.intervalindex) {

            let song_start_sec = this.playlistDurationInterval[this.intervalindex].song_start_sec;
            let currentSongVolume = this.playlistDurationInterval[this.intervalindex].volume;
            this.next(song_start_sec).then(() => {
              this.volumeIncrement(currentSongVolume);
            })
          }
          break;
      }
    },

    async next(time, currentSongVolume = 0) {
    if (this.Sptifyplayer) {
        //console.log('Spotify next track');
        const trackUri = this.songsForMix[this.intervalindex];
        const isPlaying = this.isSpotifyPlaying;
        //console.log("isPlaying", isPlaying);
        // this.playing = isPlaying;

        if (!isPlaying) {
            //console.log("playSongAndPause");
            clearTimeout(intervalflag);
            await this.$store.dispatch('playSongAndPause', trackUri);
            await this.$store.dispatch('seek', time);
            await this.$store.dispatch('pause');
            await this.$store.dispatch('setVolume', (currentSongVolume / 100) * (this.volume / 100));
            await this.$store.dispatch('pause');
            // Verify if playback resumed after seeking
            setTimeout(async() => {
              
              const currentSpotifyPlayerState = await this.$store.dispatch('currentState');
              //console.log("after playpause=> currentSpotifyPlayerState",currentSpotifyPlayerState.paused)
              //console.log("after playpause=> this.isSpotifyPlaying",this.isSpotifyPlaying)

              if ((currentSpotifyPlayerState && !currentSpotifyPlayerState.paused) || this.isSpotifyPlaying) {
                  //console.log("Playback resumed after seeking, pausing again");
                  try {
                    
                    await this.$store.dispatch('pause');
                    //console.log("after playpause=> this.isSpotifyPlaying",this.isSpotifyPlaying)

                  } catch (error) {
                    //console.log(error)
                  }
              }
            }, 1000);
        } else {
            //console.log("Playing next track");
            await this.$store.dispatch('playSong', trackUri);
            await this.$store.dispatch('seek', time);
        }

        // Update local state variables as needed
        this.currentSeconds = this.prevSongDuration;
        this.timer = this.prevSongDuration;
        this.counter = 0;

        await this.$store.dispatch('audioPlayer/updatePlayingIntervalId', this.playlistDurationInterval[this.intervalindex].interval_song_id);
        setTimeout(() => {
            this.disableButtons = false;
        }, 1000);
    } else {
        //console.log("No player available");
    }
},


    volumeIncrement(currentSongVolume) {
      let volumeIncrementInterval;
      let currentActualVolume = (currentSongVolume / 100) * (this.volume / 100);
      if (volumeIncrementInterval) {
        clearInterval(volumeIncrementInterval)
      }
      volumeIncrementInterval = setInterval( async() => {
        let spotifyCurrentVolume = await this.$store.dispatch('getVolume');
        // //console.log(spotifyCurrentVolume)
        if ((spotifyCurrentVolume + this.volumeIncrementDecrStep * currentActualVolume) > currentActualVolume || this.volume == 0) {// check before next decreament the volume will not be > 0.1
          clearInterval(volumeIncrementInterval)
          await this.$store.dispatch('setVolume',currentActualVolume);
        } else if (spotifyCurrentVolume < currentActualVolume) {
          await this.$store.dispatch('setVolume',spotifyCurrentVolume + (this.volumeIncrementDecrStep * currentActualVolume));
        }
      }, 1000);
    },
    async volumeDecreament(currentSongVolume) {
      let currentActualVolume = (currentSongVolume / 100) * (this.volume / 100);
      let minVolume = 0.1 * (this.volume / 100);
      let spotifyCurrentVolume = await this.$store.dispatch('getVolume');
      // //console.log("spotifyCurrentVolume" , spotifyCurrentVolume)
      // //console.log("step to minus",this.volumeIncrementDecrStep * currentActualVolume)
      // //console.log("will be setted",spotifyCurrentVolume - this.volumeIncrementDecrStep * currentActualVolume)
      if (( spotifyCurrentVolume - this.volumeIncrementDecrStep * currentActualVolume) > minVolume) { // check before next volumeDecreament the volume will not be > 0.1
        await this.$store.dispatch('setVolume',spotifyCurrentVolume - this.volumeIncrementDecrStep * currentActualVolume);
      }
    },

    async startPlay(song_start_sec, currentSongVolume) {
      //console.log("start play");
      //console.log("song_start_sec",song_start_sec);

      this.DJ_scratch.play();
      await new Promise(resolve => setTimeout(resolve, 200));

      // setTimeout(() => {   
        await this.$store.dispatch('seek',song_start_sec)
        await this.$store.dispatch('resume')
        await this.$store.dispatch('setVolume', (currentSongVolume / 100) * (this.volume / 100));
      // }, 1000);
    },

    nextSong() {
      if (this.playlistDurationInterval[this.playlistDurationInterval.length - 1].interval_id == this.interval_id) {
        return;
      }
      this.disableButtons = true;
      this.interval_id = this.playlistDurationInterval[this.intervalindex].interval_id;

      for (let i = this.intervalindex + 1; i < this.playlistDurationInterval.length; i++) {
        if(this.playlistDurationInterval[i].interval_id !==  this.interval_id){
          this.intervalindex = i;
          this.interval_id = this.playlistDurationInterval[this.intervalindex].interval_id;
          break;
        }
      }

      this.myIntervalIndex += 1;
      const song_start_sec = this.playlistDurationInterval[this.intervalindex].song_start_sec;
      const currentSongVolume = this.playlistDurationInterval[this.intervalindex].volume;

      if (this.intervalindex == 1) {

        this.prevSongDuration = this.playlistDurationInterval[this.intervalindex - 1].song_duration
      } else {
        var sumOfDuration = 0;
        for (let i = 0; i < this.intervalindex; i++) {
          sumOfDuration += this.playlistDurationInterval[i].song_duration;
        }
        this.prevSongDuration = sumOfDuration;
      }
      this.next(song_start_sec, currentSongVolume).then(() => {
        if(this.transitionalEffectsOn) this.playDownLifter(currentSongVolume)
      });
    },


    async goToPreviousSongOfIntervalOfMix() {
      if (this.intervalindex > 0) {
        this.disableButtons = true;
        let prevIntervalId = this.playlistDurationInterval[this.intervalindex - 1].interval_id
        this.interval_id = this.playlistDurationInterval[this.intervalindex].interval_id;
        let songOrder = this.playlistDurationInterval[this.intervalindex].song_inInterval_Order;
        let SpotifycurrentPlaybacktime = await this.$store.dispatch('currentPlaybackTime');
        let currentIntervalSongSec = SpotifycurrentPlaybacktime - this.playlistDurationInterval[this.intervalindex].song_start_sec;

        if (currentIntervalSongSec <= this.skipPrevSecLimit && prevIntervalId !== this.interval_id) {
          this.intervalindex = this.playlistDurationInterval.map(e => e.interval_id).indexOf(prevIntervalId)
          this.myIntervalIndex -= 1;
        } else {
          this.intervalindex = this.playlistDurationInterval.map(e => e.interval_id).indexOf(this.interval_id)
        }
        const song_start_sec = this.playlistDurationInterval[this.intervalindex].song_start_sec;
        const currentSongVolume = this.playlistDurationInterval[this.intervalindex].volume;

        var sumOfDuration = 0;
        for (let i = 0; i < this.intervalindex; i++) {
          sumOfDuration += this.playlistDurationInterval[i].song_duration;
        }
        this.prevSongDuration = sumOfDuration;
        this.next(song_start_sec, currentSongVolume).then(() => {
         if(this.transitionalEffectsOn) this.playDownLifter(currentSongVolume);
        });
      } else {
        this.restart();
      }
    },


    playDownLifter(currentSongVolume) {
      this.down_lifter.volume = (currentSongVolume / 100 == 1) ? this.assetsVolume : currentSongVolume / 100;
      if (this.playing) {
        this.down_lifter.pause()
        this.down_lifter.currentTime = 0
        this.down_lifter.volume = (this.volume / 100 >= this.assetsVolume) ? this.assetsVolume : this.volume / 100;
        this.down_lifter.play()
      }
    },
    async closePlayer() {

      if (this.noSleep.isEnabled) this.noSleep.disable();
      await this.$store.dispatch('pause');
      this.music.stop()
      this.music.clearQueue();
      this.$store.dispatch('audioPlayer/resetPlayerData');

      this.isPlayerActive = false;
    },

    calculateIntervalsEndTimeInSeconds(intervals) {

      let arrayWithEndSeconds = [];
      let currentIntervalEndSecond = 0;

      intervals.map((song) => {

        currentIntervalEndSecond += song.song_duration; // ending seconds for an interval
        arrayWithEndSeconds.push({
          endSeconds: currentIntervalEndSecond,
          flexEnergy: song.song.energy,
          note: song.name,
          showEndOnPlayer: song.showEndOnPlayer

        });
      });

      return arrayWithEndSeconds;
    },

    getAllIntervalsEndTimeInSeconds(intervals) {

      let allIntervalsSongs = [];

      intervals.map((interval, index) => {

        interval.interval_songs.map((intervalSong, index, arr) => {
          intervalSong.song.energy = interval.energy;
          intervalSong.name = interval.name;

          if (arr.length - 1 === index) {

            intervalSong.showEndOnPlayer = true;
          }
        });

        allIntervalsSongs.push(interval.interval_songs);
      })
      allIntervalsSongs = allIntervalsSongs.flat();

      return this.calculateIntervalsEndTimeInSeconds(allIntervalsSongs);
    },

    calcPercentEndWidth(endSeconds) {

      return Number((endSeconds / this.durationSeconds * 100).toFixed(2));
    },

    convertEndTimesFromSecondsToPercents(intervalsEndsInSeconds) {

      let intervalsEndsInPercentage = [];

      intervalsEndsInSeconds.map((interval) => {

        intervalsEndsInPercentage.push({
          endTimeOfInterval: this.calcPercentEndWidth(interval.endSeconds),
          flexEnergy: interval.flexEnergy,
          note: interval.note,
          showEndOnPlayer: interval.showEndOnPlayer
        });
      });

      return intervalsEndsInPercentage;
    },

    getIntervalsEndingTimeInPercentage(intervals) {

      let intervalsEndsInSeconds = this.getAllIntervalsEndTimeInSeconds(intervals);
      let intervalsEndsInPercentage = this.convertEndTimesFromSecondsToPercents(intervalsEndsInSeconds);

      return intervalsEndsInPercentage;
    },

    getIntervalsEndingForNotes(intervals) {

      let newArray = intervals.filter((interval) => {
        return interval.showEndOnPlayer;
      })

      return newArray;
    },

    goToPreviousIntervalOfMix() {

      if (this.percentComplete < this.intervalsEndsInPercentageInTheMix[0].endTimeOfInterval) {

        this.percentComplete = 0;
        return 0;
      }

      this.intervalsEndsInPercentageInTheMix.forEach((intervalEnd) => {

        if (this.percentComplete > intervalEnd.endTimeOfInterval) {

          this.percentComplete = intervalEnd.endTimeOfInterval;
          return intervalEnd.endTimeOfInterval;
        }
      });
    },

    goToNextIntervalOfMix() {

      if (this.percentComplete.toFixed(2) < this.intervalsEndsInPercentageInTheMix[0].endTimeOfInterval) {

        this.percentComplete = this.intervalsEndsInPercentageInTheMix[0].endTimeOfInterval;
        return this.intervalsEndsInPercentageInTheMix[0].endTimeOfInterval;
      }

      this.intervalsEndsInPercentageInTheMix.forEach((intervalEnd, index) => {

        if (this.percentComplete.toFixed(2) >= intervalEnd.endTimeOfInterval) {

          if ((index + 2) == this.intervalsEndsInPercentageInTheMix.length) {

            this.percentComplete = intervalEnd.endTimeOfInterval;
            return intervalEnd.endTimeOfInterval;
          }

          this.percentComplete = this.intervalsEndsInPercentageInTheMix[index + 1].endTimeOfInterval;
          return this.intervalsEndsInPercentageInTheMix[index + 1].endTimeOfInterval;
        }
      });
    },

    getNextIntervalNote() {

      if (this.percentComplete.toFixed(2) < this.mainIntervalsEndsInPercentage[0].endTimeOfInterval) {

        this.nextIntervalNote = this.mainIntervalsEndsInPercentage[1].note;
        return;
      }

      if (this.percentComplete.toFixed(2) >= this.mainIntervalsEndsInPercentage.at(-2).endTimeOfInterval) {

        this.nextIntervalNote = null;
        return;
      }

      this.mainIntervalsEndsInPercentage.forEach((intervalEnd, index) => {

        if (this.percentComplete.toFixed(2) >= intervalEnd.endTimeOfInterval) {

          if ((index + 2) == this.mainIntervalsEndsInPercentage.length) {

            this.nextIntervalNote = this.mainIntervalsEndsInPercentage[index + 1].note
            return;
          }

          this.nextIntervalNote = this.mainIntervalsEndsInPercentage[index + 2].note
        }
      });
    },

  },
  created() {
    this.getTransactionalEffectSettings();
    this.getSections();
    this.noSleep = new NoSleep();
    this.durationSeconds = this.endSeconds;
    this.innerLoop = this.loop;
    this.music = MusicKit.getInstance();
    this.Sptifyplayer = this.$store.getters.spotifyPlayer;

    this.createjs = window.createjs;
    this.total_duration = this.convertHHMMSStoSeconds(this.playlistDurationInterval[0].total_duration)
    this.workout_end_indicator_url = this.playlistDurationInterval[0].workout_indicator_url
    this.DJ_scratch_url = 'https://dev-reflex-radio-assets.s3.amazonaws.com/assets/Reflex+Scratch+Sample+2.wav';

    this.intervalsWithTimeline.forEach((interval) => {
      let duration = 0;
      if (interval.interval_songs.length > 1) {
        interval.interval_songs.forEach((intervalSong) => {
          duration += intervalSong.song_duration;
        })
        this.flexList.push(this.getFlexType(interval.energy));
        this.intervalDurations.push(duration);
      } else if (interval.interval_songs.length === 1) {
        this.flexList.push(this.getFlexType(interval.energy));
        this.intervalDurations.push(interval.interval_songs[0].song_duration);
      }
    })

  },

  mounted() {

    // if (this.$device.mobile) document.querySelector("#toggle").click();

    this.$nextTick(() => {
      // this.audio = this.$el.querySelectorAll('audio')[0];
      this.countbeep_audio = this.$el.querySelectorAll('audio')[0];
      this.riser_downLifter = this.$el.querySelectorAll('audio')[1];
      this.workout_end_indicator_audio = this.$el.querySelectorAll('audio')[2];
      this.down_lifter = this.$el.querySelectorAll('audio')[3];
      this.DJ_scratch = this.$el.querySelectorAll('audio')[4];
      this.workout_end_indicator_audio.addEventListener('loadeddata', (event) => {
        this.total_duration = (this.workout_end_indicator_audio.duration) ? this.total_duration - Number(this.workout_end_indicator_audio.duration) : this.total_duration;
      });
      let audiosNodeList = this.$el.querySelectorAll('audio');
      this.audios = Array.prototype.slice.call(audiosNodeList);
      this.audios.map((el => {
        el.addEventListener('ended', (event) => {
          el.currentTime = 0;
        });
      }))
      this.getNextIntervalNote();
    });
  },
  async beforeDestroy() {
    if (this.noSleep.isEnabled) this.noSleep.disable();
    this.noSleep = null;
    this.playing == false
    this.resetVariables();
    clearTimeout(intervalflag);
    await this.$store.dispatch('pause');
    await this.music.stop();
    if (!(this.$route.fullPath.includes('playlists/'))) {
      await this.$store.dispatch('pause');
      clearTimeout(intervalflag);
      await this.$store.dispatch('pause');

      await this.music.stop();
      await this.music.changeToMediaAtIndex(0);
      this.resetVariables();

    }
  }
}

</script>

<style lang="scss" scoped>

$player-bg: #fff;
$player-border-color: darken($player-bg, 12%);
$player-link-color: darken($player-bg, 75%);
$player-progress-color: $player-border-color;
$player-seeker-color: $player-link-color;
$player-text-color: $player-link-color;

.add-margin {
  cursor: default;
  padding: 0 !important;
  margin: 14px;
  text-decoration: none !important;
}

.md-width-2x {
  height: 48px;
  min-height: 48px;
  width: 48px;
  min-width: 48px;
  cursor: pointer;
}

.player-wrapper {
  align-items: center;
  background-color: $player-bg;
  background-image: linear-gradient(90deg, #fff 0, darken(#fff, 12%));
  display: flex;
  height: 100vh;
  justify-content: center;
}

.player {
  //border-radius: 5px;
  //border: 1px solid $player-border-color;
  //box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  //color: $player-text-color;
  display: flex;
  align-items: center;
  //line-height: 1.5625;
  //position: relative;
  width: 100%;
  background-color: #161616;
  height: 80px;

  #close-media-player {
    position: absolute;

    //background: $color-accent-red;
    color: white !important;
    top: 25px;
    right: -1px;
    cursor: pointer;
    z-index: 5;

    .md-icon {
      color: white !important;
      font-size: 15px !important;
    }
  }

  .div-margin {
    margin-top: 35px;
  }

  .fullscreen-button {
    position: absolute;
    background: $color-accent-red;
    color: white !important;
    top: -1px;
    left: -1px;
    cursor: pointer;
    z-index: 5;

    .md-icon {
      color: white !important;
    }
  }

  .disabled-players {
    cursor: not-allowed !important;
  }

  .player-controls {
    display: flex;
    flex: 100;
    align-items: center;
    justify-content: center;
    //position: relative;
    //
    //> div {
    //
    //  &:last-child {
    //    border-right: none;
    //  }
    //
    a {
      cursor: pointer;
      text-decoration: none;
    }

    //}
  }


  .player-time {
    display: flex;
    justify-content: space-between;

    &-start {
      margin-right: 0.5rem;
      justify-content: flex-end;
    }

    &-end {
      margin-left: 1rem;
    }

    .player-time-current {
      font-weight: 700;
      padding-left: 5px;
      padding-right: 5px;
    }

    .player-time-total {
      opacity: 0.5;
      padding-right: 5px;
    }
  }

  .md-icon {
    //color: $color-black !important;
  }

  .pause, .play {
    //color: $color-accent-red !important;
    cursor: pointer;
  }
}


.full-height {
  height: 100%;
}

.Row {
  display: table;
  text-align: start;
  width: 100%;
  table-layout: fixed;
}

.Column {
  display: table-cell;
}

.left-align {
  text-align-last: start;
  padding-left: 30px;
  padding-right: 8px;
}

.right-align {
  text-align-last: end;
  padding-right: 30px
}

.top-padding {
  padding-top: 30px;
}

.counter-center {
  //height: 50%;
  display: flex;
  flex-direction: column;
  //justify-content: center;
  //align-items: center;
  //flex-flow: column
}

.song-info-fullscreen {
  text-align: left;
  margin-left: 30px;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

div#song-name {
  text-align: center;
  margin: auto;
  padding-left: 10px;
}

.player-info-text {
  padding-top: 3px;
  padding-bottom: 0;
}

.volume-bar {
  border-radius: 2rem;

  /*Chrome*/
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    input[type='range'] {
      border-radius: 2rem;
      -webkit-appearance: none;
    }
    
    input[type='range']::-webkit-slider-thumb {
      width: 15px;
      -webkit-appearance: none;
      height: 15px;
      border-radius: 50%;
      cursor: pointer;
      background: #434343;
    }
  }
  /** FF*/
  input[type="range"]::-moz-range-progress {
    background-color: #B2B2B2;
  }

  input[type="range"]::-moz-range-track {
    background-color: #585858;
  }

  /* IE*/
  input[type="range"]::-ms-fill-lower {
    background-color: #B2B2B2;
  }

  input[type="range"]::-ms-fill-upper {
    background-color: #777777;
  }
}

.player-volume {
  display: flex;
  justify-items: center;
  height: 4px;
  margin: 0 0 0 2px;
  width: 85%;
  color: purple !important;
}

#current-time-position-playing {
  display: flex;
  margin-right: -10px;
  margin-top: -1vh;
}

#interval-end-position {
  display: flex;
  margin-right: -10px;
  margin-top: -1vh;
}

#timeline-sector {
  margin-bottom: 1rem;
}

#restart:hover, #next-interval, #prev-interval {
  cursor: pointer;
}

.mix-info-mobile-view {
  display: none;

  @media only screen and (max-width: 961px) {
    display: block;
  }
}

.mask-group {
  width: 25px;
  height: 25px;
}

.album-art {
  background-color: var(--material-themerefsecondarysecondary1007);
  border-radius: 2px;
  display: flex;
  height: 25px;
  min-width: 25px;
  overflow: hidden;
  justify-content: center;
}

.frame-433 {
  align-items: flex-start;
  display: flex;
  gap: 12px;
  margin-bottom: -3px;
}

.frame-431 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.media-player-duration {
  flex: 40;
  @media only screen and (max-width: 834px) {
    display: none;
  };
}

.media-player-volume {
  @media only screen and (max-width: 481px) {
    display: none;
  };
}

.player-timeline {
  border-radius: 0 !important;
  border: none;

  #duration-input, #duration-input-full-screen, #duration-input-full-screen2 {
    background: linear-gradient(to right, $color-accent-red 0%, transparent 0%, transparent 50%, transparent 100%);
    border-radius: inherit;
    border: none;
    height: 20px;
    outline: none;
    transition: background 450ms ease-in;
    -webkit-appearance: none;
    width: 100%;

  }

  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    input[type='range'] {
      border-radius: 2rem;
      -webkit-appearance: none;
    }
    
    input[type='range']::-webkit-slider-thumb {
      width: 20px;
      -webkit-appearance: none;
      height: 20px;
      border-radius: 50%;
      cursor: pointer;
      background: #bb6bd9;
    }
  }

  .player-progress,
  .player-seeker {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .player-progress {
    background-color: $color-accent-purple;
    z-index: 99;
    border-radius: 5rem;
  }

  .player-seeker {
    cursor: pointer;
    width: 100%;
    z-index: 2;
  }


}

.full-screen-counter {
  color: white;
  font-family: var(--font-family-figtree);
  font-weight: 500;
  height: 120px;
  letter-spacing: 0;
  line-height: normal;
  min-width: 244px;
  text-align: center;
  font-size: 80px;
  justify-content: center;
}

.workout {
  margin: 0;
  color: white;
  font-family: var(--font-family-figtree);
  font-size: var(--font-size-l);
  font-weight: 500;
  height: 100%;
}

.container {
  display: flex;
  align-items: center;
  background-color: var(--onyx);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--onyx);
  border-radius: 4px;
  height: 50px;
  //margin-top: 5px;
}

.box-1 {
  flex: 25;
}

.box-2 {
  flex: 50;
}

.fullscreen-icon {
  color: white;
  cursor: pointer;
  width: 24px;
  height: 24px;
  padding-right: 5px;
}

.button {
  color: white;
  align-items: center;
  border: 1px solid;
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  border-color: var(--white-5);
  border-radius: 5px;
}

.button-active {
  color: var(--lavender);
  font-family: var(--font-family-figtree);
  font-size: var(--font-size-s);
  font-weight: 700;
  font-style: normal;
  background-color: rgba(187, 107, 217, 0.3);
}

.tab-active {
  color: var(--lavender) !important;
  background-color: rgba(187, 107, 217, 0.5);
}

.desktop-fullscreen {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #393838;
  height: 90px;
  display: flex;
  align-items: center;
  flex-direction: column;
  opacity: 1;
}

::v-deep .md-tab {
  padding: 0;
}

.workout-box {
  display: flex;
  align-items: center;
  text-align: left;
  background-color: var(--onyx);
  border-radius: 10px;
  margin: 3px 3px 0 3px;
  padding: 4px 0 4px 4px;
}

.back-button {
  position: absolute;
  top: 25px;
  left: 25px;
  cursor: pointer
}

.summary-box {
  flex: 75;
  max-height: 100px;
  overflow: auto;
  color: #E4E4E4;
}

.desktop-div {
  flex: 40;
  overflow-y: scroll;
  height: 90vh;
  overflow-x: hidden;
}
</style>
