<template>
  <div class="main-main-content">
    <div class="overlap-group">
      <div class="rectangle-267" :style="`background-color: ${period.color ? period.color : randomColor}`"></div>
      <div class="more-option-div">
        <md-menu @click.stop class="menu-div">
          <md-button md-menu-trigger class="md-icon-button tag-input__btn">
            <md-icon style="color: white;font-size: 15px!important;">more_vert</md-icon>
          </md-button>

          <md-menu-content style="background-color: var(--onyx)!important;">
            <md-menu-item @click.stop="editPlaylist(period.id)" v-if="!isDeleted && !isArchived">
              <md-button class="figtree-medium-white-12px">Edit
                Playlist
              </md-button>
            </md-menu-item>
            <md-menu-item  @click.stop="deletePlaylist(period.id)"
                           v-if="(userRole < 5 && !isDeleted && !isArchived ) && (isDeleteDisableProp)">
              <md-button class="figtree-medium-white-12px">Delete Playlist</md-button>
            </md-menu-item>
            <md-menu-item  @click.stop="archivePlaylist(period.id)"
                           v-if="userRole < 5 && !isDeleted">
              <md-button class="figtree-medium-white-12px">{{ isArchived ? 'UnArchive' : 'Archive' }} Playlist</md-button>
            </md-menu-item>
            <md-menu-item  @click.stop="restorePlaylist(period.id)"
                           v-if="userRole < 5 && isDeleted">
              <md-button class="figtree-medium-white-12px">Restore Playlist</md-button>
            </md-menu-item>
          </md-menu-content>
        </md-menu>
      </div>
      <div class="play" @click.stop="playPlaylistMix(period.id, period.name)">
        <img class="vector" src="@/assets/images/play.png" alt="Vector"/>
      </div>
    </div>
    <div class="card-info">
      <div class="text-over-flow figtree-medium-white-14px">{{ period.name }}</div>
      <div class="figtree-normal-nobel-12px">
        <span v-if="period.has_explicit_song" class="explicit-content">E</span>
        {{ convertToHours(period.duration) }}
      </div>
    </div>
  </div>

</template>

<script>
import PlaylistService from "@/services/playlists.service"
import {mapGetters} from "vuex";
import UserService from "@/services/user.service";

export default {
  name: "PlaylistCard",

  props: {

    isArchived: {
      type: Boolean,
      default: false
    },

    period: {
      type: Object,
      default: {}
    },

    isDeleted: {
      type: Boolean,
      default: false
    },

    label: {
      type: String,
      default: "View Period"
    },

    isDeleteDisableProp: {
      type: Boolean,
      default: false
    },
    randomColor: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    timeLineSeconds: 0,
    songsForMix: []
  }),

  methods: {

    convertToHours(duration) {
      if(duration) {
        const timeObj = duration.split(':');
        const res =  (Number(timeObj[0]) !== 0 ? Number(timeObj[0]) + ' Hours ' : '') + (Number(timeObj[1]) !== 0 ? Number(timeObj[1]) + ' Minutes ' : '');
        return res === '' ? Number(timeObj[2]) + ' Seconds ' : res;
      }
    },
    restorePlaylist(id) {

      this.$emit('restoreCurrentPlaylist', id);
    },

    editPlaylist(id) {

      this.$emit('editPlaylist', id);
    },

    archivePlaylist(id) {

      if (this.isArchived) {

        this.$emit('unArchivePlaylist', id);
      } else {

        this.$emit('archivePlaylist', id);
      }
    },

    deleteSegment(id) {

      let _this = this;

      this.$emit('tempRemovePeriod', id);

      this.$store.dispatch('showSnackbarMessage', {
        message: 'Last chance to undo the delete.',
        duration: 5000,
        mode: 'success',
        buttonText: 'Undo',
        type: 'period'
      });

      setTimeout(() => {
        _this.deleteOneElement(id).then(() => {

          this.$emit('setLoadingSpinner', false);
        });
      }, 5000);
    },

    async deleteOneElement(id) {

      await this.deleteIfUndoIsInactive(id);
    },

    deletePlaylist(id) {

      PlaylistService.deletePlaylist(id)
          .then(() => {

            this.$emit('refreshPlaylists');
            this.$store.dispatch('showSnackbarMessage', {
              message: 'The playlist was successfully deleted.',
              duration: 3000,
              mode: 'success'
            });
          })
          .catch((err) => {

            this.$store.dispatch('showSnackbarMessage', {
              message: `${err.message}. Please, try again.`,
              mode: 'fail'
            });
          });
    },

    deleteIfUndoIsInactive(id) {

      if (!(this.$store.getters['periods/getIsUndoActive'])) {

        this.deletePlaylist(id);
      }

      this.$store.commit('periods/setIsUndoActive', false);
    },

    // openPlaylistModal(id) {
    //
    // 	this.$emit('showPlaylistModal', id);
    // },

    convertTimeHHMMSS(val) {

      let hhmmss = new Date(val * 1000).toISOString().substr(11, 8);

      return hhmmss;
    },
    convertHHMMSStoSeconds(timeHHMMSS) {

      let timeObj = timeHHMMSS.split(':');
      let secondsDuration = (+timeObj[0]) * 60 * 60 + (+timeObj[1]) * 60 + (+timeObj[2]);

      return secondsDuration;
    },


    getTimeline(duration) {

      let resultTimeToReturn = this.convertTimeHHMMSS(this.timeLineSeconds); // get and return last timeline

      //calculate new timeline
      let timeObj = duration.split(':');
      let secondsDuration = (+timeObj[0]) * 60 * 60 + (+timeObj[1]) * 60 + (+timeObj[2]);

      //for first timeline value
      if (this.timeLineSeconds == 0) {

        this.timeLineSeconds = secondsDuration;

        return "00:00:00";
      }

      this.timeLineSeconds = this.timeLineSeconds + secondsDuration;

      return resultTimeToReturn;
    },

    async playPlaylistMix(id, playlistName) {
      this.songsForMix = [];
      this.timeLineSeconds = 0;
      let intervalsWithTimeline = null;
      let musicIntervals = null;
      let total_duration = null;

      await this.$store.dispatch('audioPlayer/resetPlayerData');

      await PlaylistService.getIntervalsByPlaylist(id)
          .then((res) => {
            intervalsWithTimeline = res;
          })

      for (const interval of intervalsWithTimeline) {

        let timelineString = this.getTimeline(interval.duration);
        interval.timeline = timelineString;
      }
      const musicServiceId = this.currentUser.current_music_streaming_service;
      await PlaylistService.getPlaylistMixView(id)
          .then((res) => {
            musicIntervals = res;
            const map1 = new Map();

            let user_content_rating = (res[0].allow_explicit_content == 0) ? 'clean' : 'explicit';
            total_duration = this.convertHHMMSStoSeconds(res[0].total_duration)
            res.forEach(value => {
              if (map1.has(value.song_id + '_' + value.sections_order + '_' + value.intervals_order + '_' + value.song_inInterval_Order)) {
                if (map1.get(value.song_id + '_' + value.sections_order + '_' + value.intervals_order + '_' + value.song_inInterval_Order).content_rating === user_content_rating) {
                } else {
                  map1.set(value.song_id + '_' + value.sections_order + '_' + value.intervals_order + '_' + value.song_inInterval_Order, value)
                }
              } else {
                map1.set(value.song_id + '_' + value.sections_order + '_' + value.intervals_order + '_' + value.song_inInterval_Order, value)
              }
            })

            musicIntervals = Array.from(map1, ([key, value]) => {
              return value;
            });
            musicIntervals.map(el => this.songsForMix.push(el.streaming_id.toString()));
          })
          .catch((err) => {
            console.log(err)
            this.$store.dispatch('showSnackbarMessage', {
              message: `Mix was not found for this Blueprint`,
              mode: 'fail'
            });
          });

          const music = MusicKit.getInstance();
          await music.stop()
          await music.clearQueue();
          if (musicServiceId == 1){
            await this.set(this.songsForMix)
          }
          else {
              await this.$store.dispatch('playSongAndPause',this.songsForMix[0]);
          }
          const s = this.songsForMix;
      await this.$store.dispatch('audioPlayer/updatePlayerForIntervals', {
        url: null,
        name: playlistName,
        playlistId: id,
        playlistDurationInterval: musicIntervals,
        startSeconds: null,
        endSeconds: total_duration,
        autoplay: false,
        isIntervalPlaying: false,
        intervals: intervalsWithTimeline,
        appleIntervals: musicIntervals,
        songsForMix : s
      });

    },
    async set(songsForMix) {
      try {
        const music = MusicKit.getInstance();
        await music.mute()
        await music.setQueue({
              songs: songsForMix,
              startPlaying: true
            }
        );
        await music.pause();
        return;
        
      } catch (error) {
          console.log("error set queue",error)
        }
    },
    showModalTableBluePrintInfo(id) {

      this.$emit('showModalTableBluePrintInfo', id);
    },

    openURL(url) {

      window.open(url, '_blank');
    },
    getCurrentUser() {	
				return UserService.getOne(this.currentUser.id)
					.then(async (data) => {	
                        const SpotifyDBstoredRefreshToken = data.services.find(sr => sr.service_id == 2)?.token;
				    
                        if (SpotifyDBstoredRefreshToken) {
                            await this.$store.dispatch('updateSpotifyRefreshToken', SpotifyDBstoredRefreshToken); // Set token in Vuex
                            const player = this.$store.getters.spotifyPlayer;
                            await this.$store.dispatch('refreshAccessToken');
                            const spotifyToken = this.$store.getters.spotifyToken;
                            if (!player && spotifyToken)   this.$store.dispatch('initSpotifyPlayer',spotifyToken); // Initialize player with token
                        } 
					})
					.catch((err) => {	
						this.$store.dispatch('showSnackbarMessage',
							{
								message: `${err.message}. Please, try again.`,
								mode: 'fail'
							});
					});
			}
  },
  async created() {
      await this.getCurrentUser();
  },
  computed: {

    ...mapGetters(
        {
          userRole: 'auth/currentUserRole',
          currentUser: 'auth/getCurrentUser',
        },
    ),
  },
}
</script>

<style lang="scss" scoped>
@include card();

#blueprint-processing {
  margin-right: 10px;
}

.explicit-content {
  font-family: var(--font-family-figtree);
  font-size: var(--font-size-xxs);
  font-weight: bolder;
  font-style: normal;
  border: 1.5px solid var(--nobel);
  color: white;
}


.overlap-group {
  flex: 75;
  width: 100%;
}

.rectangle-267 {
  height: 100%;
  width: 100%;
}

.address {
  height: 14px;
  text-align: left;
}

.more-option-div {
  align-items: center;
  background-color: #222222;
  opacity: 0.7;
  border-radius: 12px;
  display: flex;
  height: 24px;
  justify-content: flex-end;
  left: 122px;
  min-width: 24px;
  width: 24px;
  padding: 0 4px;
  position: absolute;
  top: 4px;
}

.union {
  height: 8px;
}

.play {
  align-items: center;
  background-color: #222222;
  opacity: 0.7;
  border-radius: 16px;
  box-shadow: 0px 4px 12px -4px #19141466;
  display: flex;
  height: 32px;
  justify-content: flex-end;
  left: 114px;
  min-width: 32px;
  padding: 0 9.5px;
  position: absolute;
  top: 96px;
}

.vector {
  height: 12px;
}

.card-info {
  display: flex;
  flex: 25;
  padding-left: 12px;
  flex-direction: column;
  text-align: left;
}

.tuesday-workouts {
  height: 16px;
}

.main-main-content {
  background-color: var(--white-4);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  height: 202px;
  overflow: hidden;
  width: 152px;
  margin-bottom: 10px;
}

.menu-div {
  position: absolute;
  right: -8px;
  margin-top: 1px;
}

::v-deep .md-list.md-theme-default{
  background-color: var(--onyx)!important;
  padding: 0;
}

::v-deep .md-list-item-content{
  padding: 0;
}

</style>
