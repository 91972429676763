<template>
    <div class="component-wrap">

      <div style="display:flex" v-if="isDesktop">
        <div class="playlist-color" :style="`background-color: ${playlist.color}`" @click="editPlaylist(playlist.id)">
            <md-icon style="color: white;font-size: 40px!important; cursor: pointer;">playlist_play</md-icon>
        </div>
        <div style="text-align: left;margin-left: 35px">
            <h1 class="figtree-bold-white-46px" style="    margin-top: 8px;line-height: normal">{{ playlist.name }}</h1>
            <div class="figtree-medium-white-20px" style="margin-top:15px">{{ songsNumber }} Songs, {{ playlist.duration ?
                convertHHMMSStoHMSeconds(playlist.duration) : playlist.duration}}</div>
            <div class="action-button" style="justify-content: start;">
                <div class="play" @click.stop="play()">
                    <md-icon style="color: white;font-size: 40px!important;">play_arrow</md-icon>
                </div>
                <div class="export" @click.stop="openExportPlaylistDialog()">
                    <md-tooltip class="md-theme-light" md-direction="top">Export Playlist To Your Apple Music</md-tooltip>
                    <md-icon style="color: white;font-size: 25px!important;margin-top: 10px;cursor: pointer;">ios_share</md-icon>
                </div>
            </div>
    </div>

      </div>
    <div     v-else-if="isTablet" >
        <div class="div-top" :style="`background: linear-gradient(180deg,rgba(178, 178, 178, 0) 0%,${playlist.color} 100%)`">

            <div class="playlist-data">
              <h1 class="figtree-bold-white-46px" style="    margin-top: 8px;line-height: normal">{{ playlist.name }}</h1>
              <div  class="figtree-medium-white-20px" style="margin-top:15px">{{ songsNumber }} Songs, {{ playlist.duration ? convertHHMMSStoHMSeconds(playlist.duration) : playlist.duration}}</div>
                <div class="action-button">
                    <div class="play" @click.stop="play()">
                        <md-icon style="color: white;font-size: 40px!important;">play_arrow</md-icon>
                    </div>
                    <div class="export" @click.stop="openExportPlaylistDialog()">
                        <md-tooltip class="md-theme-light" md-direction="top">Export Playlist To Your Apple Music</md-tooltip>
                        <md-icon style="color: white;font-size: 25px!important;margin-top: 10px;cursor: pointer;">ios_share</md-icon>
                    </div>
                </div>
            </div>
        </div>        
    </div>
    <div     v-else-if="isMobile" >
        <div class="div-top" :style="`background: linear-gradient(180deg,rgba(178, 178, 178, 0) 0%,${playlist.color} 100%)`">

            <div class="playlist-data">
                <h1 class="figtree-medium-white-28px" style="    margin-top: 8px;line-height: normal">{{ playlist.name }}</h1>
                <div class="figtree-medium-white-16px" style="margin-top:15px">{{ songsNumber }} Songs, {{ playlist.duration ?
                    convertHHMMSStoHMSeconds(playlist.duration) : playlist.duration}}</div>
                <div class="action-button">
                    <div class="play" @click.stop="play()">
                        <md-icon style="color: white;font-size: 40px!important;">play_arrow</md-icon>
                    </div>
                    <div class="export" @click.stop="openExportPlaylistDialog()">
                        <md-tooltip class="md-theme-light" md-direction="top">Export Playlist To Your Apple Music</md-tooltip>
                        <md-icon  style="color: white;font-size: 25px!important;margin-top: 10px;cursor: pointer;">ios_share</md-icon>
                    </div>
                </div>
            </div>
        </div>        
    </div>

      <div style="margin-top: 40px;margin-bottom: 20px">
        <TrainerDashboardBluePrintFlexRate
            v-if="graphDataFromInterval.length"
            :graphDataProp="graphDataFromInterval"
            classProp = "graph1"
        />
      </div>

        <div class="blueprint__emptystate" v-if="intervals.length === 0">
            <md-icon class="md-size-5x md-theme-light">library_music</md-icon>
            <p class="md-body-1">Nothing added yet. Add your first music or rest section to get started.</p>
        </div>
        
        <div style="display: flex;justify-content: left;text-align: left;">

            <div style="flex:75; margin-right: 40px;margin-bottom: 15px;" class="figtree-medium-white-20px">Playlist</div>
            <div v-if="isDesktop" class="notes_search_div figtree-normal-white-18px">
                <div :class="['N_Search_tabs',{'active_tab':workoutSummaryActive}]" @click="workoutSummaryActive=true;searchActive=false">Notes</div>
                <div :class="['N_Search_tabs',{'active_tab':searchActive}]" @click="searchActive=true;workoutSummaryActive=false">Search</div>

            </div>
        </div>
    
        <div class="" style="display: -webkit-box;"  v-if="intervals.length" v-for="(section, index) in sections" :key="section.id">

            <div :class="{'work-out-width': isDesktop,'full-width':isTablet || isMobile}" >
                <div class="container figtree-medium-white-18px" >
                    <div class="box-1">
                        {{section.title }}
                    </div>
                    <div class="box-2">
                        {{convertHHMMSStoHMSeconds(section.duration) }}
                    </div>

                    <div class="box-2">
                        {{section.type_id == 1 ? 'EMOM' : section.type_id == 2  ? 'Work/Rest' : 'Custom'  }}
                    </div>
                    
                </div>
                <div >

                    <DraggableSectionIntervals 
                            :genres-prop="genres"
                            :content-rating="playlist.has_explicit_song"
                            :intervalWithPlayIconIdProp.sync="intervalWithPlayIconId"
                            :totalListIntervals.sync="listIntervalsWithTimeline"
                            :sectionId="section.id"
                            @refreshAllIntervals="getIntervalsByPlaylist"
                            @setIntervalIconToPlaying="setIntervalIconToPlaying"
                            @clearAppleSearch="searchTerm=''"
                            
                    />
                </div>
            </div>
            
            
            <div v-if="isDesktop && workoutSummaryActive" class="rectangle-319 figtree-medium-sonic-silver-14px" >
                <textarea @input="saveWorkoutSummary(section.id,section.description)" v-model="section.description"
                    class="text-area figtree-medium-sonic-silver-14px" placeholder="Add note for this workout">
                		</textarea>
            </div>
            <div v-if="isDesktop && searchActive " class="rectangle-319 figtree-medium-sonic-silver-14px" >
                <div v-if="index < 1">
                    <AppInputComponent style="padding-bottom: 0;" class="create-form__field" required placeholder="Search and drag to playlist to replace ..."
                        v-model="searchTerm" />
                    <div class="scrollbar-div" :style="{height : ((Number(intervals.length) + Number(sections.length-1) ) * 57) + (8*Number(sections.length))+'px' }"
                        v-if="searchTerm && !loading && searchResults && searchResults.data.results.songs && searchResults.data.results.songs.data">
                        <draggable  v-model="searchResults.data.results.songs.data" group="b" :move="detectMove"
                            v-if="searchTerm && !loading && searchResults && searchResults.data.results.songs && searchResults.data.results.songs.data">
                            <div class="song" v-for="(song, index) in searchResults.data.results.songs.data" :key="index" v-if="playlist.allow_explicit_content || (!playlist.allow_explicit_content && song.attributes.contentRating !== 'explicit')">
                                <div class="song-image">
                                    <img width="40px" height="40px" :src="getUrl(song.attributes.artwork, 40)"
                                        alt="`artwork of track ${track.attributes.name}`" />
                                </div>
                    
                                <div class="song-details">
                                    <p><span v-if="song.attributes.contentRating === 'explicit'" class="explicit-content figtree-normal-white-12px">E</span> {{ song.attributes.name
                                        }} ,
                                        {{ song.attributes.artistName }}</p>
                                    <a>{{ song.attributes.albumName }}</a>
                                </div>
                            </div>
                        </draggable>
                    </div>
                </div>
            </div>
            
        </div>
        
        <div
            class="audio-player"
            v-if="isIntervalPlaying == false"
        >
        
            <!-- <AppleAudioPlayer  :showPlayer="!isIntervalPlaying" :test="'test'" /> -->
            
        </div>

        <p v-if="isIntervalPlaying == false && playlist.is_processing == false && this.songName != null">
            A mix is currently playing in the media player. To edit this playlist and listen to clips, close the media
            player.
        </p>

        <PlaylistDialog :showDialog.sync="showEditPlaylistDialog"
                    :key="playlistDialogKey"
                    :doWeEdit="doWeEdit"
                    :playListProp="playlistToEdit"
                    @refreshAllPlaylists="getSinglePlaylist"
                   />
        <ExportPlaylistToAppleModel 
            :showDialog.sync="showExportPlaylistDialog"
            :key="ExportPlaylistDialogKey + '_export_playlist'"
            :name="playlistExportName"
            @ExportPlaylist="CreateApplePlaylist"
        />
        <!--		<AppFloatFabButton-->
        <!--			v-if="showTourGuide"-->
        <!--			id="exit-tour-guide"-->
        <!--			icon="close"-->
        <!--			hintText="EXIT TOUR GUIDE"-->
        <!--			@click.native="tourGuideFinish()"-->
        <!--		/>-->
    </div>
</template>

<script>
import TrainerDashboardBluePrintTrackPlaylist
    from "@/components/TrainerDashboard/TrainerDashboardBluePrintTrackPlaylist.vue";
import DraggableSectionIntervals from "@/components/TrainerDashboard/DraggableSectionIntervals.vue";
import TrainerDashboardTrackDialog from "@/components/TrainerDashboard/TrainerDashboardTrackDialog";
import TrainerDashboardBluePrintPreferences
    from '@/components/TrainerDashboard/TrainerDashboardBluePrintPreferences';
import TrainerDashboardClassPreferences from '@/components/TrainerDashboard/TrainerDashboardClassPreferences';
import TrainerDashboardBluePrintFlexRate from '@/components/TrainerDashboard/TrainerDashboardBluePrintFlexRate';
import BluePrintService from '@/services/bluePrint.service';
import IntervalService from '@/services/interval.service';
import AudioPlayer from "@/components/AudioPlayer/AudioPlayer";
import AppleAudioPlayer from "@/components/AudioPlayer/AppleAudioPlayer";
import PlaylistDialog from "../../components/TrainerDashboard/PlaylistDialog";
import ExportPlaylistToAppleModel from "../../components/TrainerDashboard/ExportPlaylistToAppleModel";
import PlaylistsService from '@/services/playlists.service'

import SectionsService from '@/services/sections.service';
import PlaylistService from "@/services/playlists.service"
import {mapGetters} from "vuex";
import draggable from 'vuedraggable'
import GenreService from "@/services/genre.service";
import UserService from "@/services/user.service";
import $axios from "@/plugins/axios";
import { CheckWindowWidthMixin } from "../../mixins/CheckWindowWidthMixin";
import { MusicKitHelperMixin } from "../../mixins/MusicKitHelperMixin";
import { playPlaylistIntervalMixin } from "../../mixins/playPlaylistIntervalMixin";
import SongService from '@/services/song.service'
import keys from '../../../apiKeys';

// import {playPlaylistIntervalMixin} from '@/mixins/playPlaylistIntervalMixin';

export default {
    name: 'TemplateDashboardPlaylist',
    mixins: [CheckWindowWidthMixin,MusicKitHelperMixin,playPlaylistIntervalMixin],

    data: () => ({
        period: {},
        playlist: {},
        intervals: [],
        timeLineSeconds: 0,
        timeLineSecondsForIntervalsWithNames: 0,
        currentSecondInterval: null,
        endCurrentIntervalSeconds: null,
        interval: {},
        genres: [],
        bluePrintName: '',
        showExerciseDialog: false,
        loadingSpinner: true,
        dialogKey: 0,
        isRest: false,
        showDialog: false,
        intervalIdTimeout: null,
        // isPlaylistEditVisible: false,
        intervalWithPlayIconId: null,
        songsForMix :[],
        editName: false,
        playListName : null,
        songsNumber: 0,
        showEditPlaylistDialog:false,
        playlistDialogKey:0,
		doWeEdit: false,
		playlistToEdit: {},
        sections:[],
        searchTerm: '',
        loading: false,
        searchResults:[],
        workoutSummaryActive:true,
        searchActive:false,
        showExportPlaylistDialog:false,
        ExportPlaylistDialogKey:0,
        playlistExportName : ''
    }),

    props: {
        periodProp: {
            type: Object,
            default() {
                return {
                    id: '',
                    intervals: '',
                    name: '',
                    order: '',
                    template_id: ''
                }

            }
        }
    },

    components: {
        draggable,
        TrainerDashboardBluePrintTrackPlaylist,
        TrainerDashboardTrackDialog,
        TrainerDashboardClassPreferences,
        TrainerDashboardBluePrintPreferences,
        TrainerDashboardBluePrintFlexRate,
        AudioPlayer,
        AppleAudioPlayer,
        PlaylistDialog,
        DraggableSectionIntervals,
        ExportPlaylistToAppleModel
    },
    // mixins:[playPlaylistIntervalMixin],

    methods: {
        openExportPlaylistDialog(){
            this.showExportPlaylistDialog = true;
            this.ExportPlaylistDialogKey++;
        },
        detectMove: function(evt){
       let toBeReplacedItemId = evt.related.attributes.id ?evt.related.attributes.id.nodeValue : '';
       if(toBeReplacedItemId){
        document.querySelectorAll('[id^="interval_"]').forEach(element => {
            element.style.opacity='1';
        });
        document.querySelectorAll('[id^="inner-interval_"]').forEach(element => {
            element.style.opacity='1';
        });  
        if (evt.willInsertAfter) {
            if (toBeReplacedItemId.split('_')[0] == 'inner-interval') {
                toBeReplacedItemId = 'inner-interval_' + toBeReplacedItemId.split('_')[1] + '_' + (Number(toBeReplacedItemId.split('_')[2])+1);
            }
            else{
                toBeReplacedItemId = 'interval_' + (Number(toBeReplacedItemId.split('_')[1])+1);
            }

        }
        if (document.getElementById(toBeReplacedItemId.toString())) {
            
            document.getElementById(toBeReplacedItemId.toString()).style.opacity='0.2';
        }
       }

    },
        listSectionIntervals(sectionId) {

            let intervalsWithTimeline = this.intervals;

            intervalsWithTimeline = intervalsWithTimeline.filter((interval) => interval.section_id == sectionId)

            return intervalsWithTimeline;
        },
        async getBluePrint(id) {
            // const id = this.$route.params.id
            await $axios.get(`/templates/${id}`)
                .then((res) => {
                    this.sections = res.data.sections;
                    this.playlistExportName = this.playlist.name + ' - '+ res.data.name
                })
                .catch((error) => {
                    let errMsg = error.response.data;
                    this.$store.dispatch('showSnackbarMessage',
                        { message: errMsg, duration: 6000, mode: 'fail' })
                });
        },

        editPlaylist(periodId) {

            PlaylistsService.getSinglePlaylist(periodId)
                .then((res) => {
                    this.doWeEdit = true;
                    this.playlistDialogKey++;
                    this.playlistToEdit = res;
                    this.showEditPlaylistDialog = true;
                })
                .catch((err) => {

                    this.$store.dispatch('showSnackbarMessage', {
                        message: `${err.message}. Please, try again.`,
                        mode: 'fail',
                    });
                })
        },

        convertHHMMSStoHMSeconds(timeHHMMSS) {
            const timeObj = timeHHMMSS.split(':');
            let res = (Number(timeObj[0]) !== 0 ? Number(timeObj[0]) + ' hrs ' : '') + (Number(timeObj[1]) !== 0 ? Number(timeObj[1]) + ' mins ' : '') +(Number(timeObj[2]) !== 0 ? Number(timeObj[2]) + ' Secs ' : '');

            return res;
        },
        tourGuideFinish() {

            this.$store.dispatch('changeIsTourGuideActive', false);
            this.$store.dispatch('changeOpenMenuForTourGuide', false);
            this.$store.dispatch('changeShowTourFinish', false);

            UserService.setActiveUserTourGuide();
            this.$tours['myTour'].finish();
            this.$router.push('/dashboard');
        },

        goBack() {

            window.clearInterval(this.intervalIdTimeout);
            this.$router.go(-1);
        },

      updateName(){
        const data = {id: this.$route.params.id, name: this.playListName};
        PlaylistService.updatePlaylist(data)
            .then(() => {
              this.getSinglePlaylist();
              this.editName = false;
              this.$store.dispatch('showSnackbarMessage', {
                message: 'Playlist name was updated successfully.',
                duration: 4000,
                mode: 'success'
              });
            })
            .catch(() => {
              this.$store.dispatch('showSnackbarMessage', {
                message: 'Failed. Please, try again.',
                duration: 8000,
                mode: 'fail'
              });
            });
      },

        removePreviousPlayingInterval() {

            let playingInterval = this.intervals.find(interval => interval.is_playing == true);

            if (playingInterval) {

                playingInterval.is_playing = false;
            }
            this.$forceUpdate();

        },

        setIntervalIconToPlaying(intervalId) {
            if (intervalId) {

                let playingInterval = null;

                this.intervals.forEach(interval => {
                    interval.interval_songs.forEach(innerInterval => {

                        if (innerInterval.id && innerInterval.id == intervalId) {

                            this.intervalWithPlayIconId = innerInterval.id;
                        }
                    });
                })

                return;
            }

            this.intervalWithPlayIconId = null;

            this.$forceUpdate();
        },

        async findNewSongForInterval(intervalId) {

            let intervalWithNewSong = null;

            if (intervalId) {

                let playingInterval = null;

                this.intervals.forEach(interval => {
                    interval.interval_songs.forEach(innerInterval => {

                        if (innerInterval.id && innerInterval.id == intervalId) {

                            intervalWithNewSong = innerInterval;
                        }
                    });
                })
            }

            return intervalWithNewSong;
        },

        convertTimeHHMMSS(val) {

            let hhmmss = new Date(val * 1000).toISOString().substr(11, 8);

            return hhmmss;
        },
            convertHHMMSStoSeconds(timeHHMMSS) {

				let timeObj = timeHHMMSS.split(':');
				let secondsDuration = (+timeObj[0]) * 60 * 60 + (+timeObj[1]) * 60 + (+timeObj[2]);

				return secondsDuration;
			},

        getTimelineForMix(duration) {

            let resultTimeToReturn = this.convertTimeHHMMSS(this.timeLineSecondsForIntervalsWithNames); // get and return last timeline

            //calculate new timeline
            let timeObj = duration.split(':');
            let secondsDuration = (+timeObj[0]) * 60 * 60 + (+timeObj[1]) * 60 + (+timeObj[2]);

            //for first timeline value
            if (this.timeLineSecondsForIntervalsWithNames == 0) {

                this.timeLineSecondsForIntervalsWithNames = secondsDuration;

                return "00:00:00";
            }

            this.timeLineSecondsForIntervalsWithNames = this.timeLineSecondsForIntervalsWithNames + secondsDuration;

            return resultTimeToReturn;
        },

        getTimeline(duration) {

            let resultTimeToReturn = this.convertTimeHHMMSS(this.timeLineSeconds); // get and return last timeline

            //calculate new timeline
            let timeObj = duration.split(':');
            let secondsDuration = (+timeObj[0]) * 60 * 60 + (+timeObj[1]) * 60 + (+timeObj[2]);

            //for first timeline value
            if (this.timeLineSeconds == 0) {

                this.timeLineSeconds = secondsDuration;

                return "00:00:00";
            }

            this.timeLineSeconds = this.timeLineSeconds + secondsDuration;

            return resultTimeToReturn;
        },

        setPageName(playlistName) {

            this.$store.dispatch('changePageName', {pageNameString: playlistName});
        },

        openExerciseDialog(isiTRest) {

            this.dialogKey++;
            this.isRest = isiTRest;
            this.showExerciseDialog = true;
            this.interval = null;
        },

        openEditModal(trackData) {

            this.getCurrentInterval(trackData);
            this.dialogKey++;
            this.showExerciseDialog = true;
        },

        async playInterval(intervalToPlay) {

            await this.$store.dispatch('audioPlayer/updatePlayerForIntervals',
                {
                    url: null,
                    name: null,
                    artist: null,
                    startSeconds: null,
                    endSeconds: null,
                    playlistId: null,
                    playlistDurationInterval: null,
                    autoplay: true,
                    isIntervalPlaying: true
                });

            await this.$store.dispatch('audioPlayer/updateIntervalToPlayWithStartSeconds',
                {
                    // url: 'https://test.reflexradio.app/upload/' + intervalToPlay.song.uuid + '.mp3',
                    // url: window.location.origin + '/upload/' + intervalToPlay.song.uuid + '.mp3',
                  url: 'https://s3.amazonaws.com/dev.music.app.reflex-radio.com/songs/' + intervalToPlay.song.uuid + '.mp3',
                    name: intervalToPlay.song.title,
                    artist: intervalToPlay.song.artist.name,
                    startSeconds: intervalToPlay.song_start_sec,
                    endSeconds: intervalToPlay.song_end_sec
                });

            // this.$emit('setIntervalIconToPlaying', intervalToPlay.id);
        },

        async playIntervalIfCurrentPlaySongWasChanged(intervalPlayedId) {

            if (intervalPlayedId) {

                let intervalToPlay = await this.findNewSongForInterval(intervalPlayedId);

                if (this.intervalWithPlayIconId == intervalToPlay.id) {

                    await this.playInterval(intervalToPlay);
                }
            }
        },

        getIntervalsByPlaylist(intervalPlayedId) {
            this.songsNumber =0;
	        let playlistId = this.$route.params.id;

            return PlaylistService.getIntervalsByPlaylist(playlistId)
                .then(async (res) => {

                    this.intervals = res;
                    this.timeLineSeconds = 0;
                    // let periodId = this.$route.params.id;
                    this.intervals.forEach((interval, index) => {
                      interval.order = index;
                      this.songsNumber += interval.interval_songs.length;

                    });
                    await this.playIntervalIfCurrentPlaySongWasChanged(intervalPlayedId);
                    await this.allIntervalGenres();
                    // await this.loadStreamingIdsForMix(playlistId)
                    this.loadingSpinner = false;
                });
        },

        getCurrentInterval(trackData) {

            // let intervalObj = this.intervals.find(x => x.id === id)
            this.interval = Object.assign({}, trackData);

            let intervalDuration = this.interval.duration;

            let durationObject = {
                min: Number(intervalDuration.split(':')[1]),
                sec: Number(intervalDuration.split(':')[2])
            };

            this.interval['durationObj'] = durationObject;
        },

        goToBluePrint(id) {

            this.$router.push('/blueprints/' + id + '/periods');
        },

        playWholeMixForTourGuide(isAutoPlayEnable) {

            this.$store.dispatch('audioPlayer/updateIntervalToPlayWithStartSeconds',
                {
                    url: null,
                    name: null,
                    startSeconds: null,
                    endSeconds: null,
                });

            this.$store.dispatch('audioPlayer/updatePlayerForIntervals',
                {
                    url: 'https://beta.reflexradio.app/upload/f02c0443-7242-4f6b-b25e-42a182620d5e.mp3',
                    name: this.playlist.name,
                    startSeconds: null,
                    endSeconds: null,
                    playlistId: this.playlist.id,
                    playlistDurationInterval: this.playlist.duration,
                    autoplay: isAutoPlayEnable,
                    isIntervalPlaying: false,
                    intervals: null
                });

            this.goNextStepIfTourGuideActive();
        },
			async playPlaylistMix(id, playlistName) {
				this.songsForMix = [];
				this.timeLineSeconds = 0;
				let intervalsWithTimeline = null;
				let musicIntervals = null;
				let total_duration = null;
				
				await this.$store.dispatch('audioPlayer/resetPlayerData');

				await PlaylistService.getIntervalsByPlaylist(id)
					.then((res) => {
                        this.intervalWithPlayIconId = null;
                        this.timeLineSecondsForIntervalsWithNames = 0;
						intervalsWithTimeline = res;
					})
				for (const interval of intervalsWithTimeline) {

					let timelineString = this.getTimelineForMix(interval.duration);
					interval.timeline = timelineString;
				}
				const musicServiceId = this.currentUser.current_music_streaming_service;
				await PlaylistService.getPlaylistMixView(id)
					.then((res) => {
						musicIntervals=res;
						const map1 = new Map();

						let user_content_rating  = (res[0].allow_explicit_content==0)?'clean':'explicit';
						total_duration = this.convertHHMMSStoSeconds(res[0].total_duration)
						res.forEach(value => {
						    if(map1.has(value.song_id + '_' + value.sections_order + '_' + value.intervals_order + '_' + value.song_inInterval_Order)) {
						        if(map1.get(value.song_id + '_' + value.sections_order + '_' + value.intervals_order + '_' + value.song_inInterval_Order).content_rating === user_content_rating ){
						        }
						        else{
						            map1.set(value.song_id + '_' + value.sections_order + '_' + value.intervals_order + '_' + value.song_inInterval_Order,value)
						        }
						    }
						    else{
						        map1.set(value.song_id + '_' + value.sections_order + '_' + value.intervals_order + '_' + value.song_inInterval_Order,value)
						    }
						})

						musicIntervals= Array.from(map1, ([key, value]) => {
						return value;
						});
						// console.log('musicIntervals ==> ',musicIntervals)
						musicIntervals.map(el=>this.songsForMix.push(el.streaming_id.toString()));
						// console.log(this.songsForMix)
					})
					.catch((err) => {
						console.log(err)
						this.$store.dispatch('showSnackbarMessage', {
							message: `Mix was not found for this Blueprint`,
							mode: 'fail'
						});
					});

				// console.log("musicIntervals =>",musicIntervals)
                const playingIntervalId = musicIntervals[0].interval_song_id
                const music = MusicKit.getInstance();
                await music.stop()
                await music.clearQueue();
                if (musicServiceId == 1){
                    await this.set(this.songsForMix)
                }
                else {
                    await this.$store.dispatch('playSongAndPause',this.songsForMix[0]);
                }
                const s = this.songsForMix;
				await	this.$store.dispatch('audioPlayer/updatePlayerForIntervals', {
						url: null,
						name: playlistName,
						playlistId: id,
						playlistDurationInterval: musicIntervals,
						startSeconds: null,
						endSeconds: total_duration,
						autoplay: false,
						isIntervalPlaying: false,
						intervals:  intervalsWithTimeline,
						appleIntervals :musicIntervals,
                        playingIntervalId: playingIntervalId,
                        songsForMix : s
					});

			},
    		async set(songsForMix) {
                try {
                      const music = MusicKit.getInstance();
                      await music.mute()
                      await music.setQueue({
                            songs: songsForMix,
                            startPlaying:true
                          }
                  );
                  await music.pause();
                  return;
                    
                } catch (error) {
                    console.log("error set queue",error)
                }
    		},
        async CreateApplePlaylist(PlaylistName) {
            const music = MusicKit.getInstance();
            let res = null;
            let applePlaylistId = null;

                const newPlaylistRequestOptions = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json", "Authorization": `Bearer ${keys.appleMusic.developerToken}`,
                        "Music-User-Token": music.musicUserToken
                    },
                    body: JSON.stringify({
                        "attributes": {
                            "name": PlaylistName,
                            "description": this.playlist.description
                        }

                    })
                };
                res = await fetch("https://api.music.apple.com/v1/me/library/playlists", newPlaylistRequestOptions)
                    .then(response => response.json());
                applePlaylistId = res.data[0].id;
                let tracksData = [];
                this.songsForMix.map(streamingId => {
                    let requestBody = { "id": `${streamingId}`, "type": "songs" };
                    tracksData.push(requestBody)
                })

                const addTracksToPlaylistRequestOptions = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json", "Authorization": `Bearer ${keys.appleMusic.developerToken}`,
                        "Music-User-Token": music.musicUserToken
                    },
                    body: JSON.stringify({
                        "data": tracksData
                    })
                };
                await fetch(`https://api.music.apple.com/v1/me/library/playlists/${applePlaylistId}/tracks`, addTracksToPlaylistRequestOptions)
                .then(()=>{
                    this.$store.dispatch('showSnackbarMessage', {
                message: 'Playlist was Exported successfully.',
                duration: 4000,
                mode: 'success'
              });
                })
        },
        play(){
                    // this.$refs.BluePrintTrackPlaylist.clearSongInteval();
                    this.playPlaylistMix(this.playlist.id,this.playlist.name)
        },

        async pauseInterval() {

            await this.$store.dispatch('audioPlayer/updatePlayerForIntervals',
                {
                    url: null,
                    name: null,
                    artist: null,
                    startSeconds: null,
                    endSeconds: null,
                    playlistId: null,
                    playlistDurationInterval: null,
                    autoplay: false,
                    isIntervalPlaying: false
                });

            this.$emit('setIntervalIconToPlaying', null);
        },

        getSinglePlaylist() {

            return PlaylistService.getSinglePlaylist(this.$route.params.id)
                .then((res) => {
                    this.playlist = res;
                })
                .catch(() => {

                    this.$store.dispatch('showSnackbarMessage', {
                        message: 'Failed. Please, try again.',
                        duration: 8000,
                        mode: 'fail'
                    });
                });
        },

        setBluePrintName(id) {

            BluePrintService.getSingleBluePrint(id)
                .then((res) => {

                    this.bluePrintName = res.name;
                })
                .catch(() => {

                    this.$store.dispatch('showSnackbarMessage', {
                        message: 'Failed. Please, try again.',
                        duration: 8000,
                        mode: 'fail'
                    });
                });
        },

        async allIntervalGenres() {

            await GenreService.getAllGenres()
                .then((res) => {

                    this.genres = res;
                })
                .catch(() => {

                    this.$store.dispatch('showSnackbarMessage', {
                        message: 'Failed. Please, try again.',
                        duration: 8000,
                        mode: 'fail'
                    });
                });
        },

        generateMixWithSavedName(updateFromDialog) {

            this.playlist.is_processing = true;
            let generateObj = {generate_mix: 1};

            if (updateFromDialog == true) {

                generateObj = {
                    name: this.playlist.name,
                    is_shared: this.playlist.is_shared,
                    generate_mix: 1
                };
            }

            PlaylistService.generateMix({data: generateObj, id: this.playlist.id})
                .then((res) => {

                    // this.isPlaylistEditVisible = true;

                    this.getMixUntilGenerated(true);

                    this.$store.dispatch('showSnackbarMessage', {
                        message: 'Mix is scheduled for generation.',
                        duration: 3000,
                        mode: 'success',
                    });
                })
                .catch(() => {

                    this.$store.dispatch('showSnackbarMessage', {
                        message: 'Failed. Please, try again.',
                        duration: 8000,
                        mode: 'fail'
                    });
                });
        },

        generateMix() {

            if (this.showTourGuide){

                this.pauseInterval();
            }

            if (this.playlist.name == 'Untitled') {

                this.dialogKey++;
                this.showDialog = true;
            } else {

                this.generateMixWithSavedName(false);
            }
        },

        cancelGeneratedMix() {

            PlaylistService.cancelGenerateMix(this.playlist.id)
                .then((res) => {

                    this.playlist = res;
                    window.clearInterval(this.intervalIdTimeout);

                    // this.isPlaylistEditVisible = false;

                    this.$store.dispatch('showSnackbarMessage', {
                        message: 'Mix has been canceled.',
                        duration: 3000,
                        mode: 'success',
                    });
                });
        },
        
        saveWorkoutSummary(sectionID,secDescription) {

            if (this.timer) {

                clearTimeout(this.timer);
                this.timer = null;
            }

            this.timer = setTimeout(() => {

                SectionsService.UpdateSectionSummary(sectionID, {description: secDescription})
                    .then((res) => {
                        this.closeAppleAudioPlayer();
                        this.$store.dispatch('showSnackbarMessage', {
                            message: 'Workout summary was saved',
                            duration: 4000,
                            mode: 'success'
                        });
                    })
            }, 2000);
        },

        async fetchData() {

            await this.getIntervalsByPlaylist();
            await this.getSinglePlaylist();
            this.playListName = this.playlist.name;
            await this.allIntervalGenres();
            await this.setBluePrintName(this.playlist.template_id);
            await this.getMixUntilGenerated(false);
            await this.getBluePrint(this.playlist.template_id)
            // await this.loadStreamingIdsForMix(this.playlist.id);

            await this.$nextTick(() => {

                this.goNextStepIfTourGuideActive();
            });
        },

        getMixUntilGenerated(isPlayAfterGetMix) {

            this.intervalIdTimeout = window.setInterval(async () => {

                await this.getSinglePlaylist();

                if (this.playlist.is_processing == false) {

                    // await this.$store.dispatch('audioPlayer/updateAutoPlay', {autoplay: false});

                    if (isPlayAfterGetMix) {
                        this.play();
                        // this.playWholeMix(false);
                    }

                    window.clearInterval(this.intervalIdTimeout);
                }
            }, 5000);
        },

        goNextStepIfTourGuideActive() {

            setTimeout(() => {

                if (this.showTourGuide) {
                    this.$nextTick(() => {

                        this.$tours['myTour'].nextStep();
                    })
                }
            }, 200);

        },
        getCurrentUser() {	
				return UserService.getOne(this.currentUser.id)
					.then(async (data) => {	
                        const SpotifyDBstoredRefreshToken = data.services.find(sr => sr.service_id == 2)?.token;
				    
                        if (SpotifyDBstoredRefreshToken) {
                            await this.$store.dispatch('updateSpotifyRefreshToken', SpotifyDBstoredRefreshToken); // Set token in Vuex
                            const player = this.$store.getters.spotifyPlayer;
                            await this.$store.dispatch('refreshAccessToken');
                            const spotifyToken = this.$store.getters.spotifyToken;
                            if (!player && spotifyToken)   this.$store.dispatch('initSpotifyPlayer',spotifyToken); // Initialize player with token
                        } 
					})
					.catch((err) => {	
						this.$store.dispatch('showSnackbarMessage',
							{
								message: `${err.message}. Please, try again.`,
								mode: 'fail'
							});
					});
			}
    },

    computed: {

        ...mapGetters(
            {
                currentUser: 'auth/getCurrentUser',
                userRole: 'auth/currentUserRole',
                isIntervalPlaying: 'audioPlayer/getIsIntervalPlaying',
                playingIntervalId: 'audioPlayer/getPlayingIntervalId',
                songName: 'audioPlayer/getSong',
                showTourGuide: 'getIsTourGuideActive',
            },
        ),

        isPlaylistEditVisible() {

            return this.playlist.is_processing;
        },

        listIntervalsWithTimeline() {

            let intervalsWithTimeline = this.intervals;

            for (const interval of intervalsWithTimeline) {

                let timelineString = this.getTimeline(interval.duration);
                interval.timeline = timelineString;
            }

            return intervalsWithTimeline;
        },

       

        graphDataFromInterval() {

            let graphData = [];

            for (const interval of this.listIntervalsWithTimeline) {

                let newIntervalFirstPoint = {};
                let newIntervalSecondPoint = {};

                //set first point
                let timeObj = interval.timeline.split(':');
                let secondsDuration = (+timeObj[0]) * 60 * 60 + (+timeObj[1]) * 60 + (+timeObj[2]);

                newIntervalFirstPoint.time = secondsDuration * 1000;
                newIntervalFirstPoint.bits = interval.energy;

                graphData.push(newIntervalFirstPoint);

                //set second point
                timeObj = interval.duration.split(':');
                let endOfIntervalPoint = (+timeObj[0]) * 60 * 60 + (+timeObj[1]) * 60 + (+timeObj[2]);
                endOfIntervalPoint = endOfIntervalPoint + secondsDuration;

                newIntervalSecondPoint.time = endOfIntervalPoint * 1000;
                newIntervalSecondPoint.bits = interval.energy;

                graphData.push(newIntervalSecondPoint);
            }

            return graphData;
        }
    },
    watch: {
        playingIntervalId(value) {
            this.setIntervalIconToPlaying(value)
            this.$forceUpdate();

        },
        async searchTerm() {
            if (!this.searchTerm) return;
            this.loading = true;
            const music = await MusicKit.getInstance();

            try {
                const queryParameters = { term: this.searchTerm, types: ['songs'], limit: 25 };
                this.searchResults = await music.api.music(`/v1/catalog/${music.storefrontCountryCode}/search`, queryParameters);
                this.loading = false;
            } catch (e) {
                console.log('error', e)
                this.loading = false;
            }

        }
    },
    async created() {
        await this.getCurrentUser();
        this.fetchData();
    },



    beforeDestroy() {

        // if (this.isIntervalPlaying == true) {

        //     this.$store.dispatch('audioPlayer/resetPlayerData');
        // }
        window.clearInterval(this.intervalIdTimeout);
    }

}
</script>

<style lang="scss" scoped>
::v-deep body.v-tour--active {
    pointer-events: auto !important;
}

.content__subtitle {
    text-align: left;
    margin: 5.6rem 0 2.4rem;
}

.title-name {
  font-weight: bold;
  font-size: 1.5em;
}

.input-center {
  height: 25px;
  padding: 5px;
  margin-top: 12px
}

.blueprint__emptystate {
    width: 250px;
    margin: 100px auto;
    text-align: center;
    color: $color-light-grey;

    .md-icon {
        margin-bottom: 2.4rem;
    }
}

.preferences {
    display: flex;
    justify-content: space-between;

    @include respond(small) {
        flex-direction: column;
    }

    & > * {
        width: 49%;
        @include respond(small) {
            width: 100%;
        }
    }
}

.tracks-table {
    position: relative;
    padding-left: 0;
    padding-right: 0;

    #overlay {
        position: absolute;
        background-color: rgba(59, 59, 88, 0.79);
        z-index: 2;
        //mix-blend-mode: exclusion;
    }

    #text {
        opacity: 1;
        position: absolute;
        font-size: 26px;
        line-height: 30px;
        font-weight: bold;
        color: white;
        z-index: 9999;
    }

    .track-hdr {
        width: 100%;
        height: 57px;
        display: flex;
        border-radius: 3px;
        transition: all .2s ease-in;
        backface-visibility: hidden;
        z-index: 1;
        position: relative;

        @include respond(medium) {
            margin-top: 3rem;
            //padding-left: 6rem;
            flex-direction: column;
            height: auto;
        }

        &__name {
            text-align: left;
            font-size: 1.4rem;
            font-weight: 400;
            color: $color-white;
            line-height: 57px;

            @include respond(medium) {
                //border-bottom: 1px solid $color-grey-light;
	            justify-content: flex-start;
	            padding-left: 0!important
            }
        }

    }

    .total-time-mix {
        font-size: 3rem;
        font-weight: 600;
        padding-top: 10px;
        padding-bottom: 10px;

        @include respond(small) {
            font-size: 2rem;
        }
    }
}

.mix-status-text {
    margin-top: auto;
    margin-bottom: auto;
    font-weight: bold;
}

.period-name {
    text-align: left;
    padding-left: 3rem;
    padding-top: 1.2rem
}
.flex-container {
  display: flex;
}
.flex-container > div {
  width: 200px;
  margin: 10px;
  text-align: center;
  vertical-align: middle;
}

.period-duration {
    text-align: right;
    padding-top: 10px;

    @include respond(small) {
        text-align: left;
    }
}

#edit-interval {
    margin-left: 2.4rem;
}

::v-deep .player {
    margin-top: 4rem;
}

.summary {
    &__title {
        margin-top: 75px;
        margin-bottom: 15px;
        text-align: left;
    }

    ::v-deep .control-field__textarea {
        height: 390px !important;
    }

    ::v-deep .control-field__input::placeholder,
    ::v-deep .control-field__textarea::placeholder {
        color: black !important;
        font-size: 16px !important;
    }

    ::v-deep .control-field__textarea {
        resize: none !important;
    }

    ::v-deep .control-field__textarea::-webkit-scrollbar {
        width: 18px;
    }
}

.md-content {
    max-width: 99%;
    max-height: 500px;
    overflow-x: hidden;
    overflow-y: scroll;

    @include respond(medium) {
        overflow: hidden;
        max-width: 100%;
        max-height: 100%;
    }

    &::-webkit-scrollbar {
        width: 15px;
    }
}

::v-deep .v-tour__target--highlighted {
    box-shadow: 0 0 0 99999px rgba(0, 0, 0, .7) !important;
}

.playlist-color{
    // background-color: var(--puerto-rico);
    border-radius: 5px;
    height: 206px;
    width: 206px;
    display: flex;
    align-items: center;
    justify-content: center;
    //max-width: 206px;
    //max-height: 206px;
    // position: relative;
}

.play{
  margin-top: 20px;
    background-color: var(--lavender);
    border-radius: 28px;
    height: 56px;
    width: 56px;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 481px) {
        margin-top: 40px;
		};
}
.export{
  margin: 20px 0 0 20px;
    background-color: var(--lavender);
    border-radius: 25px;
    height: 50px;
    width: 50px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    text-align: center;
    vertical-align: middle;
    @media only screen and (max-width: 481px) {
        margin-top: 40px;
		};
} 

.container{
    display: flex;
    align-items: center;
    // background-color: var(--shark);
    border-radius: 5px;
    // border-color: var(--onyx);
    align-items: flex-start;
    height: 56px;
    padding: 16px;
    margin: 3px 0 3px 0;
    background: #3A3A3A;
    text-align: left;
    @media only screen and (max-width: 500px) {
			font-size: small;
		};
}
.box-1 {
  flex: 30;
}
.box-2 {
  flex: 15;
}
.div-top {
  background-color: unset;
  height: 162px;
//   padding: 30px;
}
.playlist-data{
    text-align: left;
    margin-left: 35px;
    padding: 16px;
}
.rectangle-319 {
    background-color: var(--cape-cod);
    border-radius: 5px;
    height: auto;
    margin: 3px 0 3px 8px;
    width: 25%;
  }
  .text-area {
  align-items: flex-start;
  background-color:var(--cape-cod);
  border: none;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 4px 7px;
  height: 100%;
  width: 100%;
  resize: none;
  color: #E4E4E4;
}
 .workout-text{
//    border-top:1px solid red ;
//    border-radius: 5px;
border-top-right-radius: 8px;
border-top-left-radius: 8px;

margin-top: 2px;
 } 
 .work-out-width{
    width: 75%;
 }
 .full-width{
    width: 100%;
 }
 .audio-player {
		position: fixed;
		bottom: 0;
		width: 100%;
		z-index: 6;
	}
.song-details {
    flex-shrink: 0;
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;

    a,
    p {
        padding: 0;
        margin: 0;
        color: white;
        text-decoration: none;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    a:hover {
        text-decoration: underline;
    }
}
.song-image {
  height: 40px;
  border-radius: 4px;

  img {
    width: 40px;
    height: 40px;
    border-radius: 4px;
  }

  position: relative;
  background-color: white;
}
.song {
  display: flex;
  padding: 8px;
  border-radius: 8px;
  width: 95%;
  flex-shrink: 0;
  position: relative;
  cursor: pointer;
  text-align: left;
}
.scrollbar-div {
    width: 22.5%;
    top: 477px;
    background-color: #2C2C2C;
    position: absolute;
    z-index: 2;
    overflow-y: auto;
  
}
.notes_search_div{
    flex: 25;
    display: flex;
    text-align: center;
    border-bottom: 1px solid var(--gray);
    margin-bottom: 0!important;

}
.active_tab{
    border-bottom: 1px solid var(--lavender);
    color: var(--md-theme-default-primary-on-background, #bb6bd9);
}
.N_Search_tabs{
    flex: 50;
    padding-top: 5px;
    cursor: pointer;
}
.N_Search_tabs:hover{
    background-color:rgba(117, 93, 126, 0.25) ;

}
.explicit-content {
  font-family: var(--font-family-figtree);
  font-size: var(--font-size-xxs);
  font-weight: bolder;
  font-style: normal;
  border: 1.5px solid var(--nobel);
  color: white;
  margin-right: 2px;
}
.action-button{
    display:flex;
    align-items: center;
    justify-content: end;
}
</style>
